import { Faction } from '../types/lore';

// Mock faction data
export const factions: Faction[] = [
  {
    id: 'tal-dorei-council',
    name: "Tal'Dorei Council",
    type: 'government',
    campaign: 1,
    imageUrl: '/images/factions/tal-dorei-council.jpg',
    description: 'The ruling body of the Republic of Tal\'Dorei, consisting of elected officials and advisors.',
    status: 'active',
    leader: 'Sovereign Uriel Tal\'Dorei III',
    members: ['Seeker Assum Emring', 'Guardian Tofor Brotoras', 'Arbiter Brom Goldhand'],
    headquarters: 'Emon',
    firstAppearance: 'C1E1',
    territory: ['Emon', 'Republic of Tal\'Dorei'],
    allies: ['Vox Machina', 'Clasp'],
    enemies: ['Chroma Conclave', 'Remnants of the Drassig Dynasty'],
    goals: ['Maintain peace in Tal\'Dorei', 'Protect citizens', 'Foster diplomatic relations'],
    spoilerLevel: 1
  },
  {
    id: 'cerberus-assembly',
    name: 'Cerberus Assembly',
    type: 'arcane',
    campaign: 2,
    imageUrl: '/images/factions/cerberus-assembly.jpg',
    description: 'A powerful group of archmages who serve as advisors to the Dwendalian Crown and maintain magical superiority.',
    status: 'active',
    leader: 'Ludinus Da\'leth',
    members: ['Trent Ikithon', 'Vess DeRogna', 'Jenna Iresor'],
    headquarters: 'Rexxentrum',
    firstAppearance: 'C2E12',
    territory: ['Rexxentrum', 'Dwendalian Empire'],
    allies: ['Dwendalian Empire', 'Soltryce Academy'],
    enemies: ['Kryn Dynasty', 'Lord of the Mists'],
    goals: ['Maintain arcane supremacy', 'Serve the Crown', 'Research forbidden magic'],
    spoilerLevel: 2
  },
  {
    id: 'clasp',
    name: 'The Clasp',
    type: 'criminal',
    campaign: 1,
    imageUrl: '/images/factions/clasp.jpg',
    description: 'A powerful thieves\' guild operating throughout Tal\'Dorei, with significant influence in Emon.',
    status: 'active',
    leader: 'Spireling Shenn',
    members: ['Spireling Modeth', 'Spireling Zephrah'],
    headquarters: 'Emon',
    firstAppearance: 'C1E15',
    territory: ['Emon', 'Westruun', 'Kymal'],
    allies: ['Various criminal organizations', 'Corrupt officials'],
    enemies: ['Myriad', 'Law enforcement'],
    goals: ['Control criminal activities', 'Expand influence', 'Accumulate wealth'],
    spoilerLevel: 1
  },
  {
    id: 'cobalt-soul',
    name: 'The Cobalt Soul',
    type: 'monastic',
    campaign: 2,
    imageUrl: '/images/factions/cobalt-soul.jpg',
    description: 'An order of monks dedicated to the pursuit and preservation of knowledge and truth.',
    status: 'active',
    leader: 'High Curator',
    members: ['Beauregard Lionett', 'Expositor Dairon'],
    headquarters: 'Rexxentrum',
    firstAppearance: 'C2E8',
    territory: ['Rexxentrum', 'Zadash', 'Vasselheim'],
    allies: ['Dwendalian Empire', 'Library of the Cobalt Reserve'],
    enemies: ['Cerberus Assembly', 'Those who would hide the truth'],
    goals: ['Preserve knowledge', 'Expose corruption', 'Train new members'],
    spoilerLevel: 1
  },
  {
    id: 'kryn-dynasty',
    name: 'Kryn Dynasty',
    type: 'government',
    campaign: 2,
    imageUrl: '/images/factions/kryn-dynasty.jpg',
    description: 'The ruling body of Xhorhas, led by the Bright Queen and following the Luxon faith.',
    status: 'active',
    leader: 'Bright Queen Leylas Kryn',
    members: ['Essek Thelyss', 'Shadowhand Tasithir'],
    headquarters: 'Rosohna',
    firstAppearance: 'C2E18',
    territory: ['Rosohna', 'Xhorhas'],
    allies: ['Mighty Nein', 'Various Xhorhasian tribes'],
    enemies: ['Cerberus Assembly', 'Dwendalian Empire'],
    goals: ['Protect the Luxon Beacons', 'Expand territory', 'Preserve their culture'],
    spoilerLevel: 2
  }
];

// Filter factions based on criteria
export const filterFactions = (
  factions: Faction[],
  campaign?: number,
  type?: string,
  status?: string,
  searchQuery?: string
): Faction[] => {
  return factions.filter(faction => {
    // Filter by campaign
    if (campaign && faction.campaign !== campaign) {
      return false;
    }

    // Filter by type
    if (type && type !== 'all' && faction.type !== type) {
      return false;
    }

    // Filter by status
    if (status && status !== 'all' && faction.status !== status) {
      return false;
    }

    // Filter by search query
    if (searchQuery) {
      const query = searchQuery.toLowerCase();
      const matchName = faction.name.toLowerCase().includes(query);
      const matchDescription = faction.description.toLowerCase().includes(query);
      const matchLeader = faction.leader?.toLowerCase().includes(query) || false;
      const matchMembers = faction.members?.some(member => 
        member.toLowerCase().includes(query)
      ) || false;
      if (!matchName && !matchDescription && !matchLeader && !matchMembers) {
        return false;
      }
    }

    return true;
  });
};

// Get faction by ID
export const getFactionById = (id: string): Faction | undefined => {
  return factions.find(faction => faction.id === id);
};

// Get factions by type
export const getFactionsByType = (type: Faction['type']): Faction[] => {
  return factions.filter(faction => faction.type === type);
};

// Get factions by status
export const getFactionsByStatus = (status: Faction['status']): Faction[] => {
  return factions.filter(faction => faction.status === status);
};

// Get factions by territory
export const getFactionsByTerritory = (territory: string): Faction[] => {
  return factions.filter(faction => 
    faction.territory?.some(t => t.toLowerCase() === territory.toLowerCase())
  );
};

// Get factions by member
export const getFactionsByMember = (member: string): Faction[] => {
  return factions.filter(faction => 
    faction.members?.some(m => m.toLowerCase() === member.toLowerCase())
  );
};

// Get allied factions
export const getAlliedFactions = (factionId: string): Faction[] => {
  const faction = getFactionById(factionId);
  if (!faction?.allies) return [];
  
  return factions.filter(f => 
    faction.allies?.some(ally => ally === f.name)
  );
};

// Get enemy factions
export const getEnemyFactions = (factionId: string): Faction[] => {
  const faction = getFactionById(factionId);
  if (!faction?.enemies) return [];
  
  return factions.filter(f => 
    faction.enemies?.some(enemy => enemy === f.name)
  );
}; 