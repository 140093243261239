import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Container, Grid, Typography, Box, Chip } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Theme } from '@mui/material/styles';
import { Character } from '../../../types/lore';
import { getCharacters, filterCharacters } from '../../../services/lore';
import CharacterCard from '../../../components/tabletop/CharacterCard';

const Title = styled(Typography)<{ theme?: Theme }>`
  font-family: ${({ theme }) => theme.typography.fontFamily};
  font-size: ${({ theme }) => theme.typography.h2.fontSize}px;
  margin-bottom: ${({ theme }) => theme.spacing(4)};
  text-align: center;
`;

const Description = styled(Typography)<{ theme?: Theme }>`
  font-size: ${({ theme }) => theme.typography.h6.fontSize}px;
  margin-bottom: ${({ theme }) => theme.spacing(4)};
  text-align: center;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
`;

const FilterSection = styled(Box)<{ theme?: Theme }>`
  margin-bottom: ${({ theme }) => theme.spacing(4)};
  padding: ${({ theme }) => theme.spacing(2)};
  background: ${({ theme }) => theme.palette.background.paper};
  border-radius: ${({ theme }) => theme.shape.borderRadius}px;
`;

const FilterTitle = styled(Typography)<{ theme?: Theme }>`
  font-size: ${({ theme }) => theme.typography.body2.fontSize}px;
  color: ${({ theme }) => theme.palette.text.secondary};
  margin-bottom: ${({ theme }) => theme.spacing(1)};
`;

const FilterChip = styled(Chip)<{ theme?: Theme }>`
  margin: ${({ theme }) => theme.spacing(0.5)};
  font-size: ${({ theme }) => theme.typography.body2.fontSize}px;
`;

const Characters: React.FC = () => {
  const [characters, setCharacters] = useState<Character[]>([]);
  const [selectedCampaign, setSelectedCampaign] = useState<number | null>(null);
  const [selectedStatus, setSelectedStatus] = useState<string | null>(null);
  const [searchQuery, setSearchQuery] = useState('');

  useEffect(() => {
    const fetchCharacters = async () => {
      const fetchedCharacters = await getCharacters();
      setCharacters(fetchedCharacters);
    };

    fetchCharacters();
  }, []);

  const filteredCharacters = filterCharacters(characters, {
    campaign: selectedCampaign || undefined,
    status: selectedStatus || undefined,
    searchQuery: searchQuery || undefined,
  });

  const campaigns = Array.from(new Set(characters.map(char => char.campaign))).sort();
  const statuses = Array.from(new Set(characters.map(char => char.status))).sort();

  return (
    <Container maxWidth="lg" sx={{ py: 4 }}>
      <Title variant="h1">
        Characters of Critical Role
      </Title>
      <Description variant="body1" color="text.secondary">
        Explore the diverse cast of characters that have shaped the world of Exandria.
      </Description>

      <FilterSection>
        <FilterTitle variant="body2">Filter by Campaign</FilterTitle>
        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
          {campaigns.map(campaign => (
            <FilterChip
              key={campaign}
              label={`Campaign ${campaign}`}
              onClick={() => setSelectedCampaign(campaign)}
              color={selectedCampaign === campaign ? 'primary' : 'default'}
              variant={selectedCampaign === campaign ? 'filled' : 'outlined'}
            />
          ))}
        </Box>
      </FilterSection>

      <FilterSection>
        <FilterTitle variant="body2">Filter by Status</FilterTitle>
        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
          {statuses.map(status => (
            <FilterChip
              key={status}
              label={status}
              onClick={() => setSelectedStatus(status)}
              color={selectedStatus === status ? 'primary' : 'default'}
              variant={selectedStatus === status ? 'filled' : 'outlined'}
            />
          ))}
        </Box>
      </FilterSection>

      <Grid container spacing={3}>
        {filteredCharacters.map(character => (
          <Grid item xs={12} sm={6} md={4} key={character.id}>
            <Link to={`/lore/characters/${character.id}`} style={{ textDecoration: 'none' }}>
              <CharacterCard character={character} />
            </Link>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default Characters; 