import { createTheme, Theme as MUITheme, ThemeOptions } from '@mui/material/styles';
import { Shadows } from '@mui/material/styles/shadows';

// Extend the default theme type
declare module '@mui/material/styles' {
  interface Theme {
    colors: typeof baseTheme.colors;
    borderRadius: typeof baseTheme.borderRadius;
    fontSizes: typeof baseTheme.fontSizes;
    spacing: {
      (value: number): string;
      (value1: number, value2: number): string;
      xs: string;
      sm: string;
      md: string;
      lg: string;
      xl: string;
    };
  }
  interface ThemeOptions {
    colors?: typeof baseTheme.colors;
    borderRadius?: typeof baseTheme.borderRadius;
    fontSizes?: typeof baseTheme.fontSizes;
    spacing?: {
      (value: number): string;
      (value1: number, value2: number): string;
      xs: string;
      sm: string;
      md: string;
      lg: string;
      xl: string;
    };
  }
  interface TypographyVariants {
    fontFamily: {
      display: string;
      body: string;
      primary: string;
    };
  }
  interface TypographyVariantsOptions {
    fontFamily?: {
      display: string;
      body: string;
      primary: string;
    };
  }
}

// Extend the default palette options
declare module '@mui/material/styles/createPalette' {
  interface TypeBackground {
    dark: string;
  }
  interface TypeText {
    light: string;
  }
  interface Palette {
    accent: {
      gold: string;
      copper: string;
    };
  }
  interface PaletteOptions {
    accent?: {
      gold: string;
      copper: string;
    };
  }
}

const baseTheme = {
  colors: {
    primary: {
      main: '#7B1FA2',
      light: '#9C27B0',
      dark: '#6A1B9A',
    },
    secondary: {
      main: '#C2185B',
      light: '#E91E63',
      dark: '#AD1457',
    },
    background: {
      default: '#121212',
      paper: '#1E1E1E',
      dark: '#0A0A0A',
    },
    text: {
      primary: '#FFFFFF',
      secondary: 'rgba(255, 255, 255, 0.7)',
      light: 'rgba(255, 255, 255, 0.9)',
    },
    accent: {
      gold: '#FFD700',
      copper: '#B87333',
    },
  },
  borderRadius: {
    small: 4,
    medium: 8,
    large: 12,
    sm: 4,
    md: 8,
    lg: 12,
    full: '50%',
  },
  fontSizes: {
    xs: 12,
    sm: 14,
    md: 16,
    lg: 18,
    xl: 20,
    '2xl': 24,
    '3xl': 30,
    '4xl': 36,
  } as const,
};

// Create array of shadow values with proper typing
const shadows: Shadows = Array(25).fill('none') as Shadows;
shadows[1] = '0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)';
shadows[2] = '0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12)';
shadows[3] = '0px 3px 3px -2px rgba(0,0,0,0.2),0px 3px 4px 0px rgba(0,0,0,0.14),0px 1px 8px 0px rgba(0,0,0,0.12)';
shadows[4] = '0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)';
shadows[6] = '0px 3px 5px -1px rgba(0,0,0,0.2),0px 6px 10px 0px rgba(0,0,0,0.14),0px 1px 18px 0px rgba(0,0,0,0.12)';
shadows[8] = '0px 5px 5px -3px rgba(0,0,0,0.2),0px 8px 10px 1px rgba(0,0,0,0.14),0px 3px 14px 2px rgba(0,0,0,0.12)';
shadows[12] = '0px 7px 8px -4px rgba(0,0,0,0.2),0px 12px 17px 2px rgba(0,0,0,0.14),0px 5px 22px 4px rgba(0,0,0,0.12)';
shadows[16] = '0px 8px 10px -5px rgba(0,0,0,0.2),0px 16px 24px 2px rgba(0,0,0,0.14),0px 6px 30px 5px rgba(0,0,0,0.12)';
shadows[24] = '0px 11px 15px -7px rgba(0,0,0,0.2),0px 24px 38px 3px rgba(0,0,0,0.14),0px 9px 46px 8px rgba(0,0,0,0.12)';

const spacingValues = {
  xs: '0.5rem',
  sm: '1rem',
  md: '1.5rem',
  lg: '2rem',
  xl: '3rem',
};

const spacing = Object.assign(
  (value1: number, value2?: number) => {
    if (value2 !== undefined) {
      return `${value1 * 8}px ${value2 * 8}px`;
    }
    return `${value1 * 8}px`;
  },
  spacingValues
);

const themeOptions: ThemeOptions = {
  palette: {
    mode: 'dark',
    primary: {
      main: baseTheme.colors.primary.main,
      light: baseTheme.colors.primary.light,
      dark: baseTheme.colors.primary.dark,
    },
    secondary: {
      main: baseTheme.colors.secondary.main,
      light: baseTheme.colors.secondary.light,
      dark: baseTheme.colors.secondary.dark,
    },
    background: {
      default: baseTheme.colors.background.default,
      paper: baseTheme.colors.background.paper,
      dark: baseTheme.colors.background.dark,
    },
    text: {
      primary: baseTheme.colors.text.primary,
      secondary: baseTheme.colors.text.secondary,
      light: baseTheme.colors.text.light,
    },
    accent: {
      gold: baseTheme.colors.accent.gold,
      copper: baseTheme.colors.accent.copper,
    },
  },
  typography: {
    fontFamily: {
      display: '"MedievalSharp", cursive',
      body: '"Roboto", "Helvetica", "Arial", sans-serif',
      primary: '"Roboto", "Helvetica", "Arial", sans-serif',
    },
    h1: {
      fontFamily: '"MedievalSharp", cursive',
      fontSize: 36,
      fontWeight: 500,
    },
    h2: {
      fontFamily: '"MedievalSharp", cursive',
      fontSize: 30,
      fontWeight: 500,
    },
    h3: {
      fontFamily: '"MedievalSharp", cursive',
      fontSize: 24,
      fontWeight: 500,
    },
    h4: {
      fontFamily: '"MedievalSharp", cursive',
      fontSize: 20,
      fontWeight: 500,
    },
    h5: {
      fontFamily: '"MedievalSharp", cursive',
      fontSize: 18,
      fontWeight: 500,
    },
    h6: {
      fontFamily: '"MedievalSharp", cursive',
      fontSize: 16,
      fontWeight: 500,
    },
    subtitle1: {
      fontSize: 16,
      fontWeight: 400,
    },
    subtitle2: {
      fontSize: 14,
      fontWeight: 500,
    },
    body1: {
      fontSize: 16,
      fontWeight: 400,
    },
    body2: {
      fontSize: 14,
      fontWeight: 400,
    },
    button: {
      fontSize: 14,
      fontWeight: 500,
      textTransform: 'uppercase',
    },
    caption: {
      fontSize: 12,
      fontWeight: 400,
    },
    overline: {
      fontSize: 12,
      fontWeight: 400,
      textTransform: 'uppercase',
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
  spacing,
  shadows,
  shape: {
    borderRadius: baseTheme.borderRadius.medium,
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: baseTheme.borderRadius.medium,
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: baseTheme.borderRadius.medium,
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          borderRadius: baseTheme.borderRadius.medium,
        },
      },
    },
  },
};

export const theme = createTheme(themeOptions);

// Add type augmentation for emotion
declare module '@emotion/react' {
  export interface Theme extends MUITheme {
    colors: typeof baseTheme.colors;
    borderRadius: typeof baseTheme.borderRadius;
    fontSizes: typeof baseTheme.fontSizes;
    spacing: {
      (value: number): string;
      (value1: number, value2: number): string;
      xs: string;
      sm: string;
      md: string;
      lg: string;
      xl: string;
    };
  }
}

export { theme as customTheme }; 