import { Location } from '../types/lore';

// Mock location data
export const locations: Location[] = [
  {
    id: 'whitestone',
    name: 'Whitestone',
    type: 'city',
    continent: 'Tal\'Dorei',
    campaign: 1,
    imageUrl: '/images/locations/whitestone.jpg',
    description: 'A city in northern Tal\'Dorei, known for its white stone architecture and the ancient de Rolo family. The city played a crucial role in the liberation from the Briarwoods and serves as a center of technological advancement.',
    status: 'inhabited',
    notableResidents: ['Percival de Rolo', 'Cassandra de Rolo', 'Pike Trickfoot'],
    firstAppearance: 'C1E24',
    coordinates: [45.5, -122.6],
    region: 'Whitestone Valley',
    spoilerLevel: 2
  },
  {
    id: 'zadash',
    name: 'Zadash',
    type: 'city',
    continent: 'Wildemount',
    campaign: 2,
    imageUrl: '/images/locations/zadash.jpg',
    description: 'One of the major cities in the Dwendalian Empire, known for its three spires and the headquarters of the Cerberus Assembly. A hub of commerce and intrigue within the Empire.',
    status: 'inhabited',
    notableResidents: ['Pumat Sol', 'Oremid Hass'],
    firstAppearance: 'C2E8',
    coordinates: [47.6, -122.3],
    region: 'Dwendalian Empire',
    spoilerLevel: 2
  },
  {
    id: 'emon',
    name: 'Emon',
    type: 'city',
    continent: 'Tal\'Dorei',
    campaign: 1,
    imageUrl: '/images/locations/emon.jpg',
    description: 'The capital city of Tal\'Dorei, a bustling metropolis and seat of the Tal\'Dorei Council. Home to the Alabaster Lyceum and numerous magical institutions.',
    status: 'inhabited',
    notableResidents: ['Sovereign Uriel Tal\'Dorei', 'Allura Vysoren'],
    firstAppearance: 'C1E1',
    coordinates: [47.9, -122.2],
    region: 'Republic of Tal\'Dorei',
    spoilerLevel: 1
  },
  {
    id: 'jrusar',
    name: 'Jrusar',
    type: 'city',
    continent: 'Marquet',
    campaign: 3,
    imageUrl: '/images/locations/jrusar.jpg',
    description: 'A vertical city built into the Hellcatch Valley\'s spires, known for its unique architecture and diverse population. The city features multiple tiers connected by magical lifts.',
    status: 'inhabited',
    notableResidents: ['Lord Ariks Eshteross'],
    firstAppearance: 'C3E1',
    coordinates: [48.1, -122.5],
    region: 'Hellcatch Valley',
    spoilerLevel: 3
  },
  {
    id: 'vasselheim',
    name: 'Vasselheim',
    type: 'city',
    continent: 'Issylra',
    campaign: 1,
    imageUrl: '/images/locations/vasselheim.jpg',
    description: 'The oldest known city in Exandria, known as the Cradle of Faith and Dawn City. Home to temples of many deities and the Slayer\'s Take guild.',
    status: 'inhabited',
    notableResidents: ['Victor', 'Earthbreaker Groon'],
    firstAppearance: 'C1E16',
    coordinates: [48.3, -122.4],
    region: 'Othanzia',
    spoilerLevel: 2
  },
];

// Filter locations based on criteria
export const filterLocations = (
  locations: Location[],
  campaign?: number,
  type?: string,
  status?: string,
  searchQuery?: string
): Location[] => {
  return locations.filter(location => {
    // Filter by campaign
    if (campaign && location.campaign !== campaign) {
      return false;
    }

    // Filter by type
    if (type && type !== 'all' && location.type !== type) {
      return false;
    }

    // Filter by status
    if (status && status !== 'all' && location.status !== status) {
      return false;
    }

    // Filter by search query
    if (searchQuery) {
      const query = searchQuery.toLowerCase();
      const matchName = location.name.toLowerCase().includes(query);
      const matchContinent = location.continent?.toLowerCase().includes(query) || false;
      const matchDescription = location.description.toLowerCase().includes(query);
      if (!matchName && !matchContinent && !matchDescription) {
        return false;
      }
    }

    return true;
  });
};

// Get location by ID
export const getLocationById = (id: string): Location | undefined => {
  return locations.find(location => location.id === id);
};

// Get locations by continent
export const getLocationsByContinent = (continent: string): Location[] => {
  return locations.filter(location => location.continent === continent);
};

// Get locations by type
export const getLocationsByType = (type: Location['type']): Location[] => {
  return locations.filter(location => location.type === type);
};

// Get nearby locations based on coordinates
export const getNearbyLocations = (
  coordinates: [number, number],
  radius: number
): Location[] => {
  // Simple distance calculation (not accurate for real-world use)
  const [lat, lng] = coordinates;
  return locations.filter(location => {
    if (!location.coordinates) return false;
    const [locLat, locLng] = location.coordinates;
    const distance = Math.sqrt(
      Math.pow(locLat - lat, 2) + Math.pow(locLng - lng, 2)
    );
    return distance <= radius;
  });
}; 