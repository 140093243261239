import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Container, Grid, Typography, Box, Paper, Chip } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Deity } from '../../../types/lore';
import { getDeityById } from '../../../services/deities';

const Title = styled(Typography)`
  font-family: ${({ theme }) => theme.typography.fontFamily.display};
  font-size: ${({ theme }) => theme.fontSizes['4xl']}px;
  margin-bottom: ${({ theme }) => theme.spacing(2)};
`;

const Subtitle = styled(Typography)`
  font-family: ${({ theme }) => theme.typography.fontFamily.display};
  font-size: ${({ theme }) => theme.fontSizes['2xl']}px;
  color: ${({ theme }) => theme.palette.text.secondary};
  margin-bottom: ${({ theme }) => theme.spacing(4)};
`;

const Description = styled(Typography)`
  font-size: ${({ theme }) => theme.fontSizes.md}px;
  margin-bottom: ${({ theme }) => theme.spacing(4)};
  line-height: 1.6;
`;

const Section = styled(Paper)`
  padding: ${({ theme }) => theme.spacing(3)};
  margin-bottom: ${({ theme }) => theme.spacing(4)};
  background: ${({ theme }) => theme.palette.background.paper};
`;

const SectionTitle = styled(Typography)`
  font-family: ${({ theme }) => theme.typography.fontFamily.display};
  font-size: ${({ theme }) => theme.fontSizes.lg}px;
  margin-bottom: ${({ theme }) => theme.spacing(2)};
`;

const DeityImage = styled('img')`
  width: 100%;
  height: 400px;
  object-fit: cover;
  border-radius: ${({ theme }) => theme.borderRadius.lg}px;
  margin-bottom: ${({ theme }) => theme.spacing(4)};
`;

const MetaSection = styled(Box)`
  display: flex;
  flex-wrap: wrap;
  gap: ${({ theme }) => theme.spacing(2)};
  margin-bottom: ${({ theme }) => theme.spacing(4)};
`;

const MetaItem = styled(Chip)`
  font-size: ${({ theme }) => theme.fontSizes.md}px;
`;

const List = styled('ul')`
  list-style-type: none;
  padding: 0;
  margin: 0;
`;

const ListItem = styled('li')`
  font-size: ${({ theme }) => theme.fontSizes.md}px;
  margin-bottom: ${({ theme }) => theme.spacing(2)};
  padding-left: ${({ theme }) => theme.spacing(3)};
  position: relative;

  &:before {
    content: '•';
    position: absolute;
    left: 0;
    color: ${({ theme }) => theme.palette.primary.main};
  }
`;

const DeityDetail: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const [deity, setDeity] = useState<Deity | null>(null);

  useEffect(() => {
    const fetchDeity = async () => {
      if (id) {
        const fetchedDeity = await getDeityById(id);
        if (fetchedDeity) {
          setDeity(fetchedDeity);
        }
      }
    };

    fetchDeity();
  }, [id]);

  if (!deity) {
    return (
      <Container maxWidth="lg" sx={{ py: 4 }}>
        <Typography variant="h4">Loading...</Typography>
      </Container>
    );
  }

  return (
    <Container maxWidth="lg" sx={{ py: 4 }}>
      <Grid container spacing={4}>
        <Grid item xs={12} md={8}>
          {deity.imageUrl && <DeityImage src={deity.imageUrl} alt={deity.name} />}
          <MetaSection>
            <MetaItem label={deity.alignment} color="primary" variant="outlined" />
            {deity.domains.map((domain, index) => (
              <MetaItem key={index} label={domain} color="secondary" variant="outlined" />
            ))}
          </MetaSection>

          <Title variant="h1">{deity.name}</Title>
          <Subtitle variant="h2">{deity.title}</Subtitle>
          <Description variant="body1">{deity.description}</Description>

          <Section>
            <SectionTitle variant="h3">Worshipers</SectionTitle>
            <List>
              {deity.worshipers.map((worshiper, index) => (
                <ListItem key={index}>{worshiper}</ListItem>
              ))}
            </List>
          </Section>

          {deity.holyDays && deity.holyDays.length > 0 && (
            <Section>
              <SectionTitle variant="h3">Holy Days</SectionTitle>
              <List>
                {deity.holyDays.map((day, index) => (
                  <ListItem key={index}>{day}</ListItem>
                ))}
              </List>
            </Section>
          )}

          {deity.enemies && deity.enemies.length > 0 && (
            <Section>
              <SectionTitle variant="h3">Enemies</SectionTitle>
              <List>
                {deity.enemies.map((enemy, index) => (
                  <ListItem key={index}>{enemy}</ListItem>
                ))}
              </List>
            </Section>
          )}
        </Grid>

        <Grid item xs={12} md={4}>
          <Section>
            <SectionTitle variant="h3">Symbol</SectionTitle>
            <Typography variant="body1">{deity.symbol}</Typography>
          </Section>

          {deity.history && (
            <Section>
              <SectionTitle variant="h3">History</SectionTitle>
              <Typography variant="body1">{deity.history}</Typography>
            </Section>
          )}
        </Grid>
      </Grid>
    </Container>
  );
};

export default DeityDetail; 