import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Container, Grid, Typography, Box, Chip, Paper } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Character } from '../../../types/lore';
import { getCharacterById } from '../../../services/lore';

const Title = styled(Typography)`
  font-family: ${({ theme }) => theme.typography.fontFamily.display};
  font-size: ${({ theme }) => theme.fontSizes['4xl']}px;
  margin-bottom: ${({ theme }) => theme.spacing(2)};
`;

const Subtitle = styled(Typography)`
  font-family: ${({ theme }) => theme.typography.fontFamily.display};
  font-size: ${({ theme }) => theme.fontSizes['2xl']}px;
  color: ${({ theme }) => theme.palette.text.secondary};
  margin-bottom: ${({ theme }) => theme.spacing(4)};
`;

const Description = styled(Typography)`
  font-size: ${({ theme }) => theme.fontSizes.md}px;
  margin-bottom: ${({ theme }) => theme.spacing(4)};
  line-height: 1.6;
`;

const Section = styled(Paper)`
  padding: ${({ theme }) => theme.spacing(3)};
  margin-bottom: ${({ theme }) => theme.spacing(4)};
  background: ${({ theme }) => theme.palette.background.paper};
`;

const SectionTitle = styled(Typography)`
  font-family: ${({ theme }) => theme.typography.fontFamily.display};
  font-size: ${({ theme }) => theme.fontSizes.lg}px;
  margin-bottom: ${({ theme }) => theme.spacing(2)};
`;

const CharacterImage = styled('img')`
  width: 100%;
  height: 400px;
  object-fit: cover;
  border-radius: ${({ theme }) => theme.borderRadius.lg}px;
  margin-bottom: ${({ theme }) => theme.spacing(4)};
`;

const MetaSection = styled(Box)`
  display: flex;
  flex-wrap: wrap;
  gap: ${({ theme }) => theme.spacing(2)};
  margin-bottom: ${({ theme }) => theme.spacing(4)};
`;

const MetaItem = styled(Chip)`
  font-size: ${({ theme }) => theme.fontSizes.md}px;
`;

const StatGrid = styled(Grid)`
  margin-bottom: ${({ theme }) => theme.spacing(4)};
`;

const StatBox = styled(Box)`
  text-align: center;
  padding: ${({ theme }) => theme.spacing(2)};
  background: ${({ theme }) => theme.palette.background.paper};
  border-radius: ${({ theme }) => theme.borderRadius.md}px;
`;

const StatValue = styled(Typography)`
  font-size: ${({ theme }) => theme.fontSizes['2xl']}px;
  font-weight: bold;
  color: ${({ theme }) => theme.palette.primary.main};
`;

const StatLabel = styled(Typography)`
  font-size: ${({ theme }) => theme.fontSizes.sm}px;
  color: ${({ theme }) => theme.palette.text.secondary};
`;

const FeatureList = styled('ul')`
  list-style-type: none;
  padding: 0;
  margin: 0;
`;

const FeatureItem = styled('li')`
  font-size: ${({ theme }) => theme.fontSizes.md}px;
  margin-bottom: ${({ theme }) => theme.spacing(2)};
  padding-left: ${({ theme }) => theme.spacing(3)};
  position: relative;

  &:before {
    content: '•';
    position: absolute;
    left: 0;
    color: ${({ theme }) => theme.palette.primary.main};
  }
`;

const CharacterDetail: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const [character, setCharacter] = useState<Character | null>(null);

  useEffect(() => {
    const fetchCharacter = async () => {
      if (id) {
        const fetchedCharacter = await getCharacterById(id);
        if (fetchedCharacter) {
          setCharacter(fetchedCharacter);
        }
      }
    };

    fetchCharacter();
  }, [id]);

  if (!character) {
    return (
      <Container maxWidth="lg" sx={{ py: 4 }}>
        <Typography variant="h4">Loading...</Typography>
      </Container>
    );
  }

  return (
    <Container maxWidth="lg" sx={{ py: 4 }}>
      <Grid container spacing={4}>
        <Grid item xs={12} md={8}>
          <CharacterImage src={character.imageUrl} alt={character.name} />
          <MetaSection>
            <MetaItem label={`Campaign ${character.campaign}`} color="primary" variant="outlined" />
            <MetaItem label={character.status} color="secondary" variant="outlined" />
            <MetaItem label={character.race} color="info" variant="outlined" />
            {character.class.map((cls, index) => (
              <MetaItem
                key={index}
                label={`${cls} (Level ${character.level[index]})`}
                color="success"
                variant="outlined"
              />
            ))}
          </MetaSection>

          <Title variant="h1">{character.name}</Title>
          <Subtitle variant="h2">{character.background}</Subtitle>
          <Description variant="body1">{character.description}</Description>

          <Section>
            <SectionTitle variant="h3">Ability Scores</SectionTitle>
            <StatGrid container spacing={2}>
              <Grid item xs={4} sm={2}>
                <StatBox>
                  <StatValue>{character.stats.strength}</StatValue>
                  <StatLabel>STR</StatLabel>
                </StatBox>
              </Grid>
              <Grid item xs={4} sm={2}>
                <StatBox>
                  <StatValue>{character.stats.dexterity}</StatValue>
                  <StatLabel>DEX</StatLabel>
                </StatBox>
              </Grid>
              <Grid item xs={4} sm={2}>
                <StatBox>
                  <StatValue>{character.stats.constitution}</StatValue>
                  <StatLabel>CON</StatLabel>
                </StatBox>
              </Grid>
              <Grid item xs={4} sm={2}>
                <StatBox>
                  <StatValue>{character.stats.intelligence}</StatValue>
                  <StatLabel>INT</StatLabel>
                </StatBox>
              </Grid>
              <Grid item xs={4} sm={2}>
                <StatBox>
                  <StatValue>{character.stats.wisdom}</StatValue>
                  <StatLabel>WIS</StatLabel>
                </StatBox>
              </Grid>
              <Grid item xs={4} sm={2}>
                <StatBox>
                  <StatValue>{character.stats.charisma}</StatValue>
                  <StatLabel>CHA</StatLabel>
                </StatBox>
              </Grid>
            </StatGrid>
          </Section>

          <Section>
            <SectionTitle variant="h3">Features</SectionTitle>
            <FeatureList>
              {character.features.map((feature, index) => (
                <FeatureItem key={index}>{feature}</FeatureItem>
              ))}
            </FeatureList>
          </Section>
        </Grid>

        <Grid item xs={12} md={4}>
          <Section>
            <SectionTitle variant="h3">Affiliations</SectionTitle>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
              {character.affiliations.map((affiliation, index) => (
                <Chip
                  key={index}
                  label={affiliation}
                  variant="outlined"
                  color="primary"
                />
              ))}
            </Box>
          </Section>

          <Section>
            <SectionTitle variant="h3">Equipment</SectionTitle>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
              {character.equipment.map((item, index) => (
                <Chip
                  key={index}
                  label={item}
                  variant="outlined"
                  color="secondary"
                />
              ))}
            </Box>
          </Section>

          <Section>
            <SectionTitle variant="h3">Skills</SectionTitle>
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
              {character.skills.map((skill, index) => (
                <Chip
                  key={index}
                  label={skill}
                  variant="outlined"
                  color="info"
                />
              ))}
            </Box>
          </Section>

          <Section>
            <SectionTitle variant="h3">First Appearance</SectionTitle>
            <MetaItem label={`Episode ${character.firstAppearance}`} color="primary" variant="outlined" />
          </Section>
        </Grid>
      </Grid>
    </Container>
  );
};

export default CharacterDetail; 