import React from 'react';
import { Container, Typography, Grid, Paper, Button } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Link as RouterLink } from 'react-router-dom';
import {
  Casino as DiceIcon,
  Person as CharacterIcon,
  Pets as MonsterIcon,
  SportsKabaddi as EncounterIcon,
} from '@mui/icons-material';

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  transition: 'transform 0.2s ease-in-out',
  '&:hover': {
    transform: 'scale(1.02)',
  },
}));

const IconWrapper = styled('div')(({ theme }) => ({
  marginBottom: theme.spacing(2),
  '& > svg': {
    fontSize: 48,
    color: theme.palette.primary.main,
  },
}));

const Tools: React.FC = () => {
  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      <Typography variant="h2" component="h1" gutterBottom>
        Campaign Tools
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6} md={3}>
          <StyledPaper>
            <IconWrapper>
              <DiceIcon />
            </IconWrapper>
            <Typography variant="h5" gutterBottom align="center">
              Dice Roller
            </Typography>
            <Typography variant="body2" color="text.secondary" align="center" sx={{ mb: 2 }}>
              Roll virtual dice for your campaign
            </Typography>
            <Button
              component={RouterLink}
              to="/tools/dice"
              variant="contained"
              disabled
            >
              Coming Soon
            </Button>
          </StyledPaper>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <StyledPaper>
            <IconWrapper>
              <CharacterIcon />
            </IconWrapper>
            <Typography variant="h5" gutterBottom align="center">
              Character Builder
            </Typography>
            <Typography variant="body2" color="text.secondary" align="center" sx={{ mb: 2 }}>
              Create and manage characters
            </Typography>
            <Button
              component={RouterLink}
              to="/tools/characters"
              variant="contained"
              disabled
            >
              Coming Soon
            </Button>
          </StyledPaper>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <StyledPaper>
            <IconWrapper>
              <MonsterIcon />
            </IconWrapper>
            <Typography variant="h5" gutterBottom align="center">
              Monster Database
            </Typography>
            <Typography variant="body2" color="text.secondary" align="center" sx={{ mb: 2 }}>
              Browse and search monsters
            </Typography>
            <Button
              component={RouterLink}
              to="/tools/monsters"
              variant="contained"
            >
              Open Database
            </Button>
          </StyledPaper>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <StyledPaper>
            <IconWrapper>
              <EncounterIcon />
            </IconWrapper>
            <Typography variant="h5" gutterBottom align="center">
              Encounter Builder
            </Typography>
            <Typography variant="body2" color="text.secondary" align="center" sx={{ mb: 2 }}>
              Create balanced encounters
            </Typography>
            <Button
              component={RouterLink}
              to="/tools/encounters"
              variant="contained"
            >
              Build Encounter
            </Button>
          </StyledPaper>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Tools; 