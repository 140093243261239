import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Container, Grid, Typography, Box, Paper, Chip } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Theme } from '@mui/material/styles';
import { Episode } from '../../types';
import { getEpisodes, filterEpisodes } from '../../services/episodes';

const Title = styled(Typography)<{ theme?: Theme }>`
  font-family: ${({ theme }) => theme.typography.fontFamily};
  font-size: ${({ theme }) => theme.typography.h2.fontSize}px;
  margin-bottom: ${({ theme }) => theme.spacing(4)};
`;

const Description = styled(Typography)<{ theme?: Theme }>`
  font-size: ${({ theme }) => theme.typography.h6.fontSize}px;
  margin-bottom: ${({ theme }) => theme.spacing(4)};
  text-align: center;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
`;

const FilterSection = styled(Paper)<{ theme?: Theme }>`
  padding: ${({ theme }) => theme.spacing(2)};
  margin-bottom: ${({ theme }) => theme.spacing(4)};
  background: ${({ theme }) => theme.palette.background.paper};
`;

const FilterChip = styled(Chip)<{ theme?: Theme }>`
  margin: ${({ theme }) => theme.spacing(0.5)};
  font-size: ${({ theme }) => theme.typography.body2.fontSize}px;
`;

const EpisodeCard = styled(Paper)<{ theme?: Theme }>`
  padding: ${({ theme }) => theme.spacing(3)};
  margin-bottom: ${({ theme }) => theme.spacing(3)};
  background: ${({ theme }) => theme.palette.background.paper};
  border-radius: ${({ theme }) => theme.shape.borderRadius}px;
  transition: transform 0.2s ease-in-out;
  cursor: pointer;

  &:hover {
    transform: scale(1.02);
  }
`;

const EpisodeTitle = styled(Typography)<{ theme?: Theme }>`
  font-family: ${({ theme }) => theme.typography.fontFamily};
  font-size: ${({ theme }) => theme.typography.h5.fontSize}px;
  margin-bottom: ${({ theme }) => theme.spacing(1)};
`;

const EpisodeMeta = styled(Box)<{ theme?: Theme }>`
  display: flex;
  flex-wrap: wrap;
  gap: ${({ theme }) => theme.spacing(1)};
  margin-bottom: ${({ theme }) => theme.spacing(2)};
`;

const MetaChip = styled(Chip)<{ theme?: Theme }>`
  font-size: ${({ theme }) => theme.typography.body2.fontSize}px;
`;

const Episodes: React.FC = () => {
  const [episodes, setEpisodes] = useState<Episode[]>([]);
  const [selectedCampaign, setSelectedCampaign] = useState<number | null>(null);
  const [searchQuery, setSearchQuery] = useState('');

  useEffect(() => {
    const fetchEpisodes = async () => {
      try {
        const fetchedEpisodes = await getEpisodes();
        setEpisodes(fetchedEpisodes);
      } catch (error) {
        console.error('Error fetching episodes:', error);
      }
    };

    fetchEpisodes();
  }, []);

  const filteredEpisodes = filterEpisodes(episodes, {
    campaign: selectedCampaign || undefined,
    searchQuery: searchQuery || undefined,
  });

  return (
    <Container maxWidth="lg" sx={{ py: 4 }}>
      <Title variant="h1">Episodes</Title>
      <Description variant="body1" color="text.secondary">
        Watch and explore the adventures of Vox Machina, the Mighty Nein, and Bells Hells.
      </Description>

      <FilterSection>
        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
          <FilterChip
            label="All Campaigns"
            onClick={() => setSelectedCampaign(null)}
            color={selectedCampaign === null ? 'primary' : 'default'}
            variant={selectedCampaign === null ? 'filled' : 'outlined'}
          />
          <FilterChip
            label="Campaign 1"
            onClick={() => setSelectedCampaign(1)}
            color={selectedCampaign === 1 ? 'primary' : 'default'}
            variant={selectedCampaign === 1 ? 'filled' : 'outlined'}
          />
          <FilterChip
            label="Campaign 2"
            onClick={() => setSelectedCampaign(2)}
            color={selectedCampaign === 2 ? 'primary' : 'default'}
            variant={selectedCampaign === 2 ? 'filled' : 'outlined'}
          />
          <FilterChip
            label="Campaign 3"
            onClick={() => setSelectedCampaign(3)}
            color={selectedCampaign === 3 ? 'primary' : 'default'}
            variant={selectedCampaign === 3 ? 'filled' : 'outlined'}
          />
        </Box>
      </FilterSection>

      <Grid container spacing={3}>
        {filteredEpisodes.map(episode => (
          <Grid item xs={12} key={episode.id}>
            <Link to={`/episodes/${episode.id}`} style={{ textDecoration: 'none' }}>
              <EpisodeCard>
                <EpisodeTitle variant="h5">
                  {episode.title}
                </EpisodeTitle>
                <EpisodeMeta>
                  <MetaChip label={`Campaign ${episode.campaign}`} color="primary" variant="outlined" />
                  <MetaChip label={`Episode ${episode.episodeNumber}`} color="secondary" variant="outlined" />
                  <MetaChip label={episode.airDate} variant="outlined" />
                  <MetaChip label={`${Math.floor(episode.duration / 60)}h ${episode.duration % 60}m`} variant="outlined" />
                </EpisodeMeta>
                <Typography variant="body1" color="text.secondary">
                  {episode.summary}
                </Typography>
              </EpisodeCard>
            </Link>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default Episodes; 