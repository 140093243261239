import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Container,
  Grid,
  Paper,
  Typography,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Card,
  CardContent,
  CardMedia,
  Box,
  Chip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  CircularProgress,
  Alert,
  Button,
  Tooltip,
} from '@mui/material';
import { Close as CloseIcon, Add as AddIcon } from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import { Monster, MonsterListItem } from '../../types/monster';
import { dndApi } from '../../services/dndApi';

const StyledCard = styled(Card)(({ theme }) => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  transition: 'transform 0.2s ease-in-out',
  cursor: 'pointer',
  '&:hover': {
    transform: 'scale(1.02)',
  },
}));

const StyledChip = styled(Chip)(({ theme }) => ({
  margin: theme.spacing(0.5),
}));

const MonsterDatabase: React.FC = () => {
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedType, setSelectedType] = useState('all');
  const [selectedCR, setSelectedCR] = useState('all');
  const [selectedMonster, setSelectedMonster] = useState<Monster | null>(null);
  const [monsters, setMonsters] = useState<MonsterListItem[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchMonsters = async () => {
      try {
        setLoading(true);
        const monsterList = await dndApi.getMonsterList();
        setMonsters(monsterList);
        setError(null);
      } catch (err) {
        setError('Failed to load monsters. Please try again later.');
        console.error('Error fetching monsters:', err);
      } finally {
        setLoading(false);
      }
    };

    fetchMonsters();
  }, []);

  const handleMonsterClick = async (monsterId: string) => {
    try {
      setLoading(true);
      const monster = await dndApi.getMonster(monsterId);
      setSelectedMonster(monster);
      setError(null);
    } catch (err) {
      setError('Failed to load monster details. Please try again later.');
      console.error('Error fetching monster details:', err);
    } finally {
      setLoading(false);
    }
  };

  const handleAddToEncounter = (monster: MonsterListItem | Monster) => {
    // Store the monster in session storage
    const existingMonsters = JSON.parse(sessionStorage.getItem('pendingEncounterMonsters') || '[]');
    sessionStorage.setItem('pendingEncounterMonsters', JSON.stringify([...existingMonsters, { monster, count: 1 }]));
    
    // Navigate to encounter builder
    navigate('/tools/encounters');
  };

  const filteredMonsters = monsters.filter(monster => {
    const matchesSearch = monster.name.toLowerCase().includes(searchTerm.toLowerCase());
    const matchesType = selectedType === 'all' || monster.type === selectedType;
    const matchesCR = selectedCR === 'all' || monster.challengeRating.toString() === selectedCR;
    return matchesSearch && matchesType && matchesCR;
  });

  const monsterTypes = Array.from(new Set(monsters.map(m => m.type))).sort();
  const challengeRatings = Array.from(new Set(monsters.map(m => m.challengeRating)))
    .sort((a, b) => a - b)
    .map(cr => cr.toString());

  const getModifier = (stat: number) => {
    const modifier = Math.floor((stat - 10) / 2);
    return modifier >= 0 ? `+${modifier}` : modifier.toString();
  };

  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      <Typography variant="h2" component="h1" gutterBottom>
        Monster Database
      </Typography>

      {error && (
        <Alert severity="error" sx={{ mb: 2 }}>
          {error}
        </Alert>
      )}

      <Grid container spacing={3} sx={{ mb: 4 }}>
        <Grid item xs={12} md={4}>
          <TextField
            fullWidth
            label="Search Monsters"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <FormControl fullWidth>
            <InputLabel>Monster Type</InputLabel>
            <Select
              value={selectedType}
              label="Monster Type"
              onChange={(e) => setSelectedType(e.target.value)}
            >
              <MenuItem value="all">All Types</MenuItem>
              {monsterTypes.map((type) => (
                <MenuItem key={type} value={type}>{type}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={4}>
          <FormControl fullWidth>
            <InputLabel>Challenge Rating</InputLabel>
            <Select
              value={selectedCR}
              label="Challenge Rating"
              onChange={(e) => setSelectedCR(e.target.value)}
            >
              <MenuItem value="all">All CRs</MenuItem>
              {challengeRatings.map((cr) => (
                <MenuItem key={cr} value={cr}>CR {cr}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>

      {loading && !selectedMonster ? (
        <Box display="flex" justifyContent="center" my={4}>
          <CircularProgress />
        </Box>
      ) : (
        <Grid container spacing={3}>
          {filteredMonsters.map((monster) => (
            <Grid item xs={12} sm={6} md={4} key={monster.id}>
              <StyledCard>
                <CardContent>
                  <Box display="flex" justifyContent="space-between" alignItems="flex-start">
                    <Box onClick={() => handleMonsterClick(monster.id)} sx={{ cursor: 'pointer', flex: 1 }}>
                      <Typography variant="h6" gutterBottom>
                        {monster.name}
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        {monster.size} {monster.type}
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        CR {monster.challengeRating}
                      </Typography>
                    </Box>
                    <Tooltip title="Add to Encounter">
                      <IconButton
                        onClick={() => handleAddToEncounter(monster)}
                        size="small"
                        color="primary"
                      >
                        <AddIcon />
                      </IconButton>
                    </Tooltip>
                  </Box>
                </CardContent>
              </StyledCard>
            </Grid>
          ))}
        </Grid>
      )}

      <Dialog
        open={!!selectedMonster}
        onClose={() => setSelectedMonster(null)}
        maxWidth="md"
        fullWidth
      >
        {loading ? (
          <Box display="flex" justifyContent="center" my={4}>
            <CircularProgress />
          </Box>
        ) : selectedMonster && (
          <>
            <DialogTitle>
              <Box display="flex" justifyContent="space-between" alignItems="center">
                <Box flex={1}>{selectedMonster.name}</Box>
                <Box>
                  <Tooltip title="Add to Encounter">
                    <IconButton
                      onClick={() => handleAddToEncounter(selectedMonster)}
                      size="small"
                      color="primary"
                      sx={{ mr: 1 }}
                    >
                      <AddIcon />
                    </IconButton>
                  </Tooltip>
                  <IconButton onClick={() => setSelectedMonster(null)}>
                    <CloseIcon />
                  </IconButton>
                </Box>
              </Box>
            </DialogTitle>
            <DialogContent>
              <Typography variant="subtitle1" gutterBottom>
                {selectedMonster.size} {selectedMonster.type}, {selectedMonster.alignment}
              </Typography>

              <Box sx={{ my: 2 }}>
                <Typography variant="body2">
                  <strong>Armor Class:</strong> {selectedMonster.armorClass}
                </Typography>
                <Typography variant="body2">
                  <strong>Hit Points:</strong> {selectedMonster.hitPoints}
                </Typography>
                <Typography variant="body2">
                  <strong>Speed:</strong> {Object.entries(selectedMonster.speed)
                    .map(([type, value]) => `${type} ${value} ft.`)
                    .join(', ')}
                </Typography>
              </Box>

              <TableContainer component={Paper} sx={{ my: 2 }}>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell>STR</TableCell>
                      <TableCell>DEX</TableCell>
                      <TableCell>CON</TableCell>
                      <TableCell>INT</TableCell>
                      <TableCell>WIS</TableCell>
                      <TableCell>CHA</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      {Object.values(selectedMonster.stats).map((stat, index) => (
                        <TableCell key={index}>
                          {stat} ({getModifier(stat)})
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>

              {selectedMonster.savingThrows && Object.keys(selectedMonster.savingThrows).length > 0 && (
                <Box sx={{ my: 2 }}>
                  <Typography variant="subtitle2" gutterBottom>
                    Saving Throws
                  </Typography>
                  {Object.entries(selectedMonster.savingThrows).map(([ability, bonus]) => (
                    <Chip
                      key={ability}
                      label={`${ability.toUpperCase()} +${bonus}`}
                      size="small"
                      sx={{ mr: 1, mb: 1 }}
                    />
                  ))}
                </Box>
              )}

              {selectedMonster.skills && Object.keys(selectedMonster.skills).length > 0 && (
                <Box sx={{ my: 2 }}>
                  <Typography variant="subtitle2" gutterBottom>
                    Skills
                  </Typography>
                  {Object.entries(selectedMonster.skills).map(([skill, bonus]) => (
                    <Chip
                      key={skill}
                      label={`${skill} +${bonus}`}
                      size="small"
                      sx={{ mr: 1, mb: 1 }}
                    />
                  ))}
                </Box>
              )}

              {selectedMonster.damageResistances && selectedMonster.damageResistances.length > 0 && (
                <Box sx={{ my: 2 }}>
                  <Typography variant="subtitle2" gutterBottom>
                    Damage Resistances
                  </Typography>
                  {selectedMonster.damageResistances.map((resistance) => (
                    <Chip
                      key={resistance}
                      label={resistance}
                      size="small"
                      sx={{ mr: 1, mb: 1 }}
                    />
                  ))}
                </Box>
              )}

              {selectedMonster.damageImmunities && selectedMonster.damageImmunities.length > 0 && (
                <Box sx={{ my: 2 }}>
                  <Typography variant="subtitle2" gutterBottom>
                    Damage Immunities
                  </Typography>
                  {selectedMonster.damageImmunities.map((immunity) => (
                    <Chip
                      key={immunity}
                      label={immunity}
                      size="small"
                      sx={{ mr: 1, mb: 1 }}
                    />
                  ))}
                </Box>
              )}

              {selectedMonster.conditionImmunities && selectedMonster.conditionImmunities.length > 0 && (
                <Box sx={{ my: 2 }}>
                  <Typography variant="subtitle2" gutterBottom>
                    Condition Immunities
                  </Typography>
                  {selectedMonster.conditionImmunities.map((immunity) => (
                    <Chip
                      key={immunity}
                      label={immunity}
                      size="small"
                      sx={{ mr: 1, mb: 1 }}
                    />
                  ))}
                </Box>
              )}

              <Box sx={{ my: 2 }}>
                <Typography variant="body2">
                  <strong>Languages:</strong> {selectedMonster.languages.join(', ')}
                </Typography>
                <Typography variant="body2">
                  <strong>Challenge Rating:</strong> {selectedMonster.challengeRating} ({selectedMonster.xp} XP)
                </Typography>
              </Box>

              {selectedMonster.abilities?.length > 0 && (
                <Box sx={{ my: 2 }}>
                  <Typography variant="h6" gutterBottom>
                    Abilities
                  </Typography>
                  {selectedMonster.abilities.map((ability, index) => (
                    <Box key={index} sx={{ mb: 1 }}>
                      <Typography variant="subtitle2">
                        {ability.name}
                      </Typography>
                      <Typography variant="body2">
                        {ability.description}
                      </Typography>
                    </Box>
                  ))}
                </Box>
              )}

              {selectedMonster.actions?.length > 0 && (
                <Box sx={{ my: 2 }}>
                  <Typography variant="h6" gutterBottom>
                    Actions
                  </Typography>
                  {selectedMonster.actions.map((action, index) => (
                    <Box key={index} sx={{ mb: 1 }}>
                      <Typography variant="subtitle2">
                        {action.name}
                      </Typography>
                      <Typography variant="body2">
                        {action.description}
                      </Typography>
                      {action.damage && (
                        <Typography variant="body2" color="text.secondary">
                          Damage: {action.damage.diceCount}d{action.damage.diceValue} {action.damage.type}
                        </Typography>
                      )}
                    </Box>
                  ))}
                </Box>
              )}

              {selectedMonster.legendaryActions && selectedMonster.legendaryActions.length > 0 && (
                <Box sx={{ my: 2 }}>
                  <Typography variant="h6" gutterBottom>
                    Legendary Actions
                  </Typography>
                  {selectedMonster.legendaryActions.map((action, index) => (
                    <Box key={index} sx={{ mb: 1 }}>
                      <Typography variant="subtitle2">
                        {action.name}
                      </Typography>
                      <Typography variant="body2">
                        {action.description}
                      </Typography>
                    </Box>
                  ))}
                </Box>
              )}
            </DialogContent>
          </>
        )}
      </Dialog>
    </Container>
  );
};

export default MonsterDatabase; 