import React, { useState } from 'react';
import { Outlet, useNavigate, useLocation } from 'react-router-dom';
import styled from '@emotion/styled';
import { motion } from 'framer-motion';
import { useTheme } from '@mui/material/styles';

const LoreLayout: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [searchQuery, setSearchQuery] = useState('');
  const theme = useTheme();

  const Container = styled.div`
    max-width: 1400px;
    margin: 0 auto;
    padding: ${theme.spacing(4)};
    display: flex;
    gap: ${theme.spacing(4)};

    @media (max-width: 768px) {
      flex-direction: column;
      padding: ${theme.spacing(2)};
    }
  `;

  const Sidebar = styled.div`
    width: 250px;
    flex-shrink: 0;
    background: ${theme.palette.background.paper}CC;
    border-radius: ${theme.shape.borderRadius}px;
    border: 1px solid ${theme.palette.primary.main}33;
    padding: ${theme.spacing(3)};
    height: fit-content;

    @media (max-width: 768px) {
      width: 100%;
    }
  `;

  const MainContent = styled.div`
    flex: 1;
    min-width: 0; // Prevents flex item from overflowing
  `;

  const CategoryTitle = styled.h2`
    font-family: ${theme.typography.fontFamily.display};
    font-size: ${theme.typography.h5.fontSize}px;
    color: ${theme.palette.text.primary};
    margin-bottom: ${theme.spacing(2)};
    padding-bottom: ${theme.spacing(1)};
    border-bottom: 1px solid ${theme.palette.primary.main}33;
  `;

  const NavList = styled.ul`
    list-style: none;
    padding: 0;
    margin: 0;
  `;

  const NavItem = styled(motion.li)<{ active?: boolean }>`
    margin-bottom: ${theme.spacing(1)};
    
    a {
      display: block;
      padding: ${theme.spacing(1)} ${theme.spacing(2)};
      color: ${props => props.active ? theme.palette.primary.main : theme.palette.text.primary};
      text-decoration: none;
      border-radius: ${theme.shape.borderRadius}px;
      font-family: ${theme.typography.fontFamily.primary};
      transition: all 0.3s ease-in-out;
      
      &:hover {
        background: ${theme.palette.primary.main}22;
        color: ${theme.palette.primary.main};
      }
    }
  `;

  const SearchBar = styled.input`
    width: 100%;
    padding: ${theme.spacing(2)};
    background: ${theme.palette.background.default};
    border: 1px solid ${theme.palette.primary.main}33;
    border-radius: ${theme.shape.borderRadius}px;
    color: ${theme.palette.text.primary};
    margin-bottom: ${theme.spacing(4)};
    font-family: ${theme.typography.fontFamily.body};

    &:focus {
      outline: none;
      border-color: ${theme.palette.primary.main};
    }
  `;

  const categories = [
    { id: 'characters', label: 'Characters', path: '/lore/characters' },
    { id: 'locations', label: 'Locations', path: '/lore/locations' },
    { id: 'items', label: 'Items & Artifacts', path: '/lore/items' },
    { id: 'factions', label: 'Factions', path: '/lore/factions' },
    { id: 'events', label: 'Historical Events', path: '/lore/events' },
    { id: 'deities', label: 'Deities', path: '/lore/deities' },
  ];

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value);
    // TODO: Implement search functionality
  };

  return (
    <Container>
      <Sidebar>
        <SearchBar
          type="text"
          placeholder="Search lore..."
          value={searchQuery}
          onChange={handleSearch}
        />
        <CategoryTitle>Categories</CategoryTitle>
        <NavList>
          {categories.map((category) => (
            <NavItem
              key={category.id}
              active={location.pathname === category.path}
              whileHover={{ x: 4 }}
              whileTap={{ scale: 0.98 }}
            >
              <a
                href={category.path}
                onClick={(e) => {
                  e.preventDefault();
                  navigate(category.path);
                }}
              >
                {category.label}
              </a>
            </NavItem>
          ))}
        </NavList>
      </Sidebar>
      <MainContent>
        <Outlet />
      </MainContent>
    </Container>
  );
};

export default LoreLayout; 