import React, { useEffect, useState, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import styled from '@emotion/styled';
import { motion } from 'framer-motion';
import mapboxgl from 'mapbox-gl';
import { Theme as MUITheme } from '@mui/material/styles';
import { Box, Card, CardContent, Chip, Grid, Typography } from '@mui/material';
import { customTheme } from '../../../theme/theme';
import { Location } from '../../../types/lore';
import { getLocationById, getNearbyLocations } from '../../../services/locations';

type StyledComponent = {
  theme?: MUITheme;
}

const Container = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: ${({ theme }) => theme.spacing(4)};
`;

const BackButton = styled(motion.button)`
  background: none;
  border: none;
  color: ${({ theme }) => theme.palette.primary.main};
  font-family: ${({ theme }) => theme.typography.fontFamily};
  font-size: 1rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing(1)};
  margin-bottom: ${({ theme }) => theme.spacing(4)};
  padding: ${({ theme }) => theme.spacing(1)};
  border-radius: ${({ theme }) => theme.shape.borderRadius}px;

  &:hover {
    background: ${({ theme }) => theme.palette.primary.main}22;
  }
`;

const Header = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacing(4)};
  margin-bottom: ${({ theme }) => theme.spacing(4)};

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const ImageContainer = styled.div`
  flex-shrink: 0;
  width: 400px;
  height: 300px;
  border-radius: ${({ theme }) => theme.shape.borderRadius}px;
  overflow: hidden;
  border: 2px solid ${({ theme }) => theme.palette.primary.main}33;
  position: relative;

  @media (max-width: 768px) {
    width: 100%;
    height: 200px;
  }
`;

const LocationImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const HeaderInfo = styled.div`
  flex: 1;
`;

const Title = styled.h1`
  font-family: ${({ theme }) => theme.typography.fontFamily};
  font-size: 2rem;
  color: ${({ theme }) => theme.palette.text.primary};
  margin-bottom: ${({ theme }) => theme.spacing(1)};
`;

const MetaInfo = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: ${({ theme }) => theme.spacing(2)};
  margin-bottom: ${({ theme }) => theme.spacing(4)};
`;

const MetaItem = styled.div`
  background: ${({ theme }) => theme.palette.background.paper}CC;
  padding: ${({ theme }) => theme.spacing(2)};
  border-radius: ${({ theme }) => theme.shape.borderRadius}px;
  border: 1px solid ${({ theme }) => theme.palette.primary.main}33;
`;

const MetaLabel = styled.div`
  font-size: 0.875rem;
  color: ${({ theme }) => theme.palette.text.secondary};
  margin-bottom: ${({ theme }) => theme.spacing(1)};
`;

const MetaValue = styled.div`
  font-family: ${({ theme }) => theme.typography.fontFamily};
  color: ${({ theme }) => theme.palette.text.primary};
`;

const Section = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing(4)};
`;

const SectionTitle = styled.h2`
  font-family: ${({ theme }) => theme.typography.fontFamily};
  font-size: 1.5rem;
  color: ${({ theme }) => theme.palette.text.primary};
  margin-bottom: ${({ theme }) => theme.spacing(3)};
  padding-bottom: ${({ theme }) => theme.spacing(1)};
  border-bottom: 1px solid ${({ theme }) => theme.palette.primary.main}33;
`;

const Description = styled.div`
  font-family: ${({ theme }) => theme.typography.fontFamily};
  font-size: 1rem;
  line-height: 1.8;
  color: ${({ theme }) => theme.palette.text.primary};
  margin-bottom: ${({ theme }) => theme.spacing(4)};
`;

const MapContainer = styled.div`
  width: 100%;
  height: 400px;
  border-radius: ${({ theme }) => theme.shape.borderRadius}px;
  overflow: hidden;
  border: 1px solid ${({ theme }) => theme.palette.primary.main}33;
  margin-bottom: ${({ theme }) => theme.spacing(4)};
`;

const ResidentsList = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: ${({ theme }) => theme.spacing(2)};
`;

const ResidentCard = styled.div`
  padding: ${({ theme }) => theme.spacing(2)};
  background: ${({ theme }) => theme.palette.background.paper}CC;
  border-radius: ${({ theme }) => theme.shape.borderRadius}px;
  border: 1px solid ${({ theme }) => theme.palette.primary.main}33;
  cursor: pointer;
  &:hover {
    border-color: ${({ theme }) => theme.palette.primary.main};
  }
`;

const NearbyLocations = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: ${({ theme }) => theme.spacing(2)};
`;

const NearbyLocationCard = styled(motion.div)`
  padding: ${({ theme }) => theme.spacing(2)};
  background: ${({ theme }) => theme.palette.background.paper}CC;
  border-radius: ${({ theme }) => theme.shape.borderRadius}px;
  border: 1px solid ${({ theme }) => theme.palette.primary.main}33;
  cursor: pointer;
  &:hover {
    border-color: ${({ theme }) => theme.palette.primary.main};
  }
`;

const LocationDetail: React.FC = () => {
  const { locationId } = useParams<{ locationId: string }>();
  const navigate = useNavigate();
  const mapContainer = useRef<HTMLDivElement>(null);
  const map = useRef<mapboxgl.Map | null>(null);
  const [location, setLocation] = useState<Location | null>(null);
  const [nearbyLocations, setNearbyLocations] = useState<Location[]>([]);

  useEffect(() => {
    if (locationId) {
      const locationData = getLocationById(locationId);
      if (locationData) {
        setLocation(locationData);
        if (locationData.coordinates) {
          setNearbyLocations(getNearbyLocations(locationData.coordinates, 0.1));
        }
      }
    }
  }, [locationId]);

  useEffect(() => {
    if (location?.coordinates && mapContainer.current) {
      map.current = new mapboxgl.Map({
        container: mapContainer.current,
        style: 'mapbox://styles/mapbox/dark-v10',
        center: [location.coordinates[1], location.coordinates[0]],
        zoom: 12,
      });

      new mapboxgl.Marker()
        .setLngLat([location.coordinates[1], location.coordinates[0]])
        .addTo(map.current);
    }

    return () => {
      if (map.current) {
        map.current.remove();
      }
    };
  }, [location]);

  if (!location) {
    return (
      <Container>
        <BackButton onClick={() => navigate('/lore/locations')}>
          ← Back to Locations
        </BackButton>
        <div>Location not found</div>
      </Container>
    );
  }

  return (
    <Container>
      <BackButton onClick={() => navigate('/lore/locations')}>
        ← Back to Locations
      </BackButton>

      <Header>
        <ImageContainer>
          <LocationImage 
            src={location.imageUrl || '/images/location-placeholder.jpg'} 
            alt={location.name}
          />
        </ImageContainer>

        <HeaderInfo>
          <Title>{location.name}</Title>
          <MetaInfo>
            <MetaItem>
              <MetaLabel>Type</MetaLabel>
              <MetaValue>{location.type}</MetaValue>
            </MetaItem>
            <MetaItem>
              <MetaLabel>Continent</MetaLabel>
              <MetaValue>{location.continent}</MetaValue>
            </MetaItem>
            <MetaItem>
              <MetaLabel>Status</MetaLabel>
              <MetaValue>{location.status}</MetaValue>
            </MetaItem>
            <MetaItem>
              <MetaLabel>First Appearance</MetaLabel>
              <MetaValue>{location.firstAppearance}</MetaValue>
            </MetaItem>
          </MetaInfo>
        </HeaderInfo>
      </Header>

      <Section>
        <MapContainer ref={mapContainer} />
      </Section>

      <Section>
        <SectionTitle>Description</SectionTitle>
        <Description>{location.description}</Description>
      </Section>

      {location.notableResidents && location.notableResidents.length > 0 && (
        <Section>
          <SectionTitle>Notable Residents</SectionTitle>
          <ResidentsList>
            {location.notableResidents.map((resident) => (
              <ResidentCard key={resident}>
                {resident}
              </ResidentCard>
            ))}
          </ResidentsList>
        </Section>
      )}

      {nearbyLocations.length > 0 && (
        <Section>
          <SectionTitle>Nearby Locations</SectionTitle>
          <NearbyLocations>
            {nearbyLocations
              .filter(nearby => nearby.id !== location.id)
              .map((nearby) => (
                <NearbyLocationCard
                  key={nearby.id}
                  onClick={() => navigate(`/lore/locations/${nearby.id}`)}
                  whileHover={{ scale: 1.02 }}
                  whileTap={{ scale: 0.98 }}
                >
                  <h3>{nearby.name}</h3>
                  <p>{nearby.type}</p>
                </NearbyLocationCard>
              ))}
          </NearbyLocations>
        </Section>
      )}
    </Container>
  );
};

export default LocationDetail; 