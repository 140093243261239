import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, Typography, Box, Paper, Chip } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Theme } from '@mui/material/styles';
import { Event } from '../../../types/lore';
import { events, filterEvents } from '../../../services/events';

const Title = styled(Typography)<{ theme?: Theme }>`
  font-family: ${({ theme }) => theme.typography.fontFamily};
  font-size: ${({ theme }) => theme.typography.h2.fontSize}px;
  color: ${({ theme }) => theme.palette.text.primary};
  margin-bottom: ${({ theme }) => theme.spacing(4)};
`;

const FilterBar = styled(Box)<{ theme?: Theme }>`
  display: flex;
  gap: ${({ theme }) => theme.spacing(2)};
  margin-bottom: ${({ theme }) => theme.spacing(4)};
  flex-wrap: wrap;
`;

const SearchInput = styled('input')<{ theme?: Theme }>`
  flex: 1;
  min-width: 200px;
  background: ${({ theme }) => theme.palette.background.default};
  border: 1px solid ${({ theme }) => theme.palette.primary.main}33;
  border-radius: ${({ theme }) => theme.shape.borderRadius}px;
  padding: ${({ theme }) => theme.spacing(2)};
  color: ${({ theme }) => theme.palette.text.primary};
  font-family: ${({ theme }) => theme.typography.fontFamily};

  &:focus {
    outline: none;
    border-color: ${({ theme }) => theme.palette.primary.main};
  }
`;

const Select = styled('select')<{ theme?: Theme }>`
  background: ${({ theme }) => theme.palette.background.default};
  border: 1px solid ${({ theme }) => theme.palette.primary.main}33;
  border-radius: ${({ theme }) => theme.shape.borderRadius}px;
  padding: ${({ theme }) => theme.spacing(2)};
  color: ${({ theme }) => theme.palette.text.primary};
  font-family: ${({ theme }) => theme.typography.fontFamily};
  min-width: 150px;
  cursor: pointer;

  &:focus {
    outline: none;
    border-color: ${({ theme }) => theme.palette.primary.main};
  }

  option {
    background: ${({ theme }) => theme.palette.background.default};
    color: ${({ theme }) => theme.palette.text.primary};
  }
`;

const Timeline = styled(Box)<{ theme?: Theme }>`
  position: relative;
  padding: ${({ theme }) => theme.spacing(4)} 0;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 4px;
    height: 100%;
    background: ${({ theme }) => theme.palette.primary.main}33;
  }
`;

const TimelineEvent = styled(Box)<{ theme?: Theme; align: 'left' | 'right' }>`
  width: calc(50% - ${({ theme }) => theme.spacing(4)});
  margin: ${({ theme }) => theme.spacing(4)} 0;
  position: relative;
  ${({ theme, align }) => align === 'right' ? `margin-left: calc(50% + ${theme.spacing(4)});` : ''}

  &::before {
    content: '';
    position: absolute;
    top: 50%;
    ${({ theme, align }) => align === 'right' ? 'left' : 'right'}: -${({ theme }) => theme.spacing(4)};
    transform: translateY(-50%);
    width: ${({ theme }) => theme.spacing(4)};
    height: 2px;
    background: ${({ theme }) => theme.palette.primary.main}33;
  }

  &::after {
    content: '';
    position: absolute;
    top: 50%;
    ${({ theme, align }) => align === 'right' ? 'left' : 'right'}: calc(-${({ theme }) => theme.spacing(4)} - 4px);
    transform: translate(${({ align }) => align === 'right' ? '-50%' : '50%'}, -50%);
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background: ${({ theme }) => theme.palette.primary.main};
  }
`;

const EventCard = styled(Paper)<{ theme?: Theme }>`
  padding: ${({ theme }) => theme.spacing(3)};
  background: ${({ theme }) => theme.palette.background.paper}CC;
  border-radius: ${({ theme }) => theme.shape.borderRadius}px;
  border: 1px solid ${({ theme }) => theme.palette.primary.main}33;
  cursor: pointer;
  transition: transform 0.2s ease-in-out;

  &:hover {
    transform: scale(1.02);
    border-color: ${({ theme }) => theme.palette.primary.main};
  }
`;

const EventTitle = styled(Typography)<{ theme?: Theme }>`
  font-family: ${({ theme }) => theme.typography.fontFamily};
  font-size: ${({ theme }) => theme.typography.h5.fontSize}px;
  color: ${({ theme }) => theme.palette.text.primary};
  margin-bottom: ${({ theme }) => theme.spacing(1)};
`;

const EventMeta = styled(Box)<{ theme?: Theme }>`
  display: flex;
  gap: ${({ theme }) => theme.spacing(1)};
  margin-bottom: ${({ theme }) => theme.spacing(2)};
  flex-wrap: wrap;
`;

const MetaChip = styled(Chip)<{ theme?: Theme }>`
  font-size: ${({ theme }) => theme.typography.body2.fontSize}px;
  background: ${({ theme }) => theme.palette.background.paper}CC;
`;

const EventDescription = styled(Typography)<{ theme?: Theme }>`
  font-size: ${({ theme }) => theme.typography.body2.fontSize}px;
  color: ${({ theme }) => theme.palette.text.secondary};
`;

const Events: React.FC = () => {
  const navigate = useNavigate();
  const [filteredEvents, setFilteredEvents] = useState<Event[]>(events);
  const [filter, setFilter] = useState({
    campaign: 'all',
    type: 'all',
    search: '',
  });

  useEffect(() => {
    const campaign = filter.campaign === 'all' ? undefined : parseInt(filter.campaign);
    const type = filter.type === 'all' ? undefined : filter.type;
    const searchQuery = filter.search || undefined;
    
    const filtered = filterEvents(events, campaign, type, undefined, undefined, searchQuery);
    setFilteredEvents(filtered);
  }, [filter]);

  const handleFilterChange = (key: keyof typeof filter, value: string) => {
    setFilter(prev => ({ ...prev, [key]: value }));
  };

  return (
    <Container maxWidth="lg" sx={{ py: 4 }}>
      <Title variant="h1">Historical Events</Title>
      
      <FilterBar>
        <SearchInput
          type="text"
          placeholder="Search events..."
          value={filter.search}
          onChange={(e) => handleFilterChange('search', e.target.value)}
        />
        <Select
          value={filter.campaign}
          onChange={(e) => handleFilterChange('campaign', e.target.value)}
        >
          <option value="all">All Campaigns</option>
          <option value="0">Pre-Stream</option>
          <option value="1">Campaign 1</option>
          <option value="2">Campaign 2</option>
          <option value="3">Campaign 3</option>
        </Select>
        <Select
          value={filter.type}
          onChange={(e) => handleFilterChange('type', e.target.value)}
        >
          <option value="all">All Types</option>
          <option value="battle">Battles</option>
          <option value="political">Political</option>
          <option value="catastrophe">Catastrophes</option>
          <option value="celebration">Celebrations</option>
        </Select>
      </FilterBar>

      <Timeline>
        {filteredEvents.map((event, index) => (
          <TimelineEvent
            key={event.id}
            align={index % 2 === 0 ? 'left' : 'right'}
            onClick={() => navigate(`/lore/events/${event.id}`)}
          >
            <EventCard elevation={2}>
              <EventTitle variant="h5">{event.name}</EventTitle>
              <EventMeta>
                <MetaChip label={`Campaign ${event.campaign}`} />
                <MetaChip label={event.type} />
                {event.date && <MetaChip label={event.date} />}
                {event.location && <MetaChip label={event.location} />}
              </EventMeta>
              <EventDescription variant="body2">
                {event.description}
              </EventDescription>
            </EventCard>
          </TimelineEvent>
        ))}
      </Timeline>
    </Container>
  );
};

export default Events; 