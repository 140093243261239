import React, { useState } from 'react';
import {
  AppBar,
  Box,
  Button,
  Drawer,
  IconButton,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Toolbar,
  Typography,
  useTheme,
  Collapse,
} from '@mui/material';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';
import HomeIcon from '@mui/icons-material/Home';
import PeopleIcon from '@mui/icons-material/People';
import MapIcon from '@mui/icons-material/Map';
import BookIcon from '@mui/icons-material/Book';
import BuildIcon from '@mui/icons-material/Build';
import ForumIcon from '@mui/icons-material/Forum';
import DashboardIcon from '@mui/icons-material/Dashboard';
import PetsIcon from '@mui/icons-material/Pets';
import SportsKabaddiIcon from '@mui/icons-material/SportsKabaddi';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import styled from '@emotion/styled';

const StyledDrawer = styled(Drawer)`
  .MuiDrawer-paper {
    width: 240px;
    background-color: ${props => props.theme.palette.background.paper};
  }
`;

const Logo = styled(Typography)`
  flex-grow: 1;
  font-family: ${props => props.theme.typography.fontFamily.display};
  font-size: 1.5rem;
` as typeof Typography;

const menuItems = [
  { text: 'Home', icon: <HomeIcon />, path: '/' },
  { text: 'Episodes', icon: <BookIcon />, path: '/episodes' },
  { text: 'Map', icon: <MapIcon />, path: '/map' },
  { text: 'Lore', icon: <BookIcon />, path: '/lore' },
  {
    text: 'Tools',
    icon: <BuildIcon />,
    path: '/tools',
    subItems: [
      { text: 'Monster Database', icon: <PetsIcon />, path: '/tools/monsters' },
      { text: 'Encounter Builder', icon: <SportsKabaddiIcon />, path: '/tools/encounters' },
    ],
  },
  { text: 'Community', icon: <ForumIcon />, path: '/community' },
  { text: 'Dashboard', icon: <DashboardIcon />, path: '/dashboard' },
];

const Navigation: React.FC = () => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [toolsOpen, setToolsOpen] = useState(false);
  const theme = useTheme();
  const location = useLocation();

  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };

  const handleToolsClick = () => {
    setToolsOpen(!toolsOpen);
  };

  const isCurrentPath = (path: string) => {
    return location.pathname === path || location.pathname.startsWith(path + '/');
  };

  const drawer = (
    <Box>
      <List>
        {menuItems.map((item) => (
          <React.Fragment key={item.text}>
            {item.subItems ? (
              <>
                <ListItemButton
                  onClick={handleToolsClick}
                  sx={{
                    backgroundColor: isCurrentPath(item.path) ? theme.palette.action.selected : 'transparent',
                    '&:hover': {
                      backgroundColor: theme.palette.action.hover,
                    },
                  }}
                >
                  <ListItemIcon>{item.icon}</ListItemIcon>
                  <ListItemText primary={item.text} />
                  {toolsOpen ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>
                <Collapse in={toolsOpen} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    {item.subItems.map((subItem) => (
                      <ListItemButton
                        key={subItem.text}
                        component={RouterLink}
                        to={subItem.path}
                        onClick={toggleDrawer}
                        sx={{
                          pl: 4,
                          backgroundColor: isCurrentPath(subItem.path) ? theme.palette.action.selected : 'transparent',
                          '&:hover': {
                            backgroundColor: theme.palette.action.hover,
                          },
                        }}
                      >
                        <ListItemIcon>{subItem.icon}</ListItemIcon>
                        <ListItemText primary={subItem.text} />
                      </ListItemButton>
                    ))}
                  </List>
                </Collapse>
              </>
            ) : (
              <ListItemButton
                component={RouterLink}
                to={item.path}
                onClick={toggleDrawer}
                sx={{
                  backgroundColor: isCurrentPath(item.path) ? theme.palette.action.selected : 'transparent',
                  '&:hover': {
                    backgroundColor: theme.palette.action.hover,
                  },
                }}
              >
                <ListItemIcon>{item.icon}</ListItemIcon>
                <ListItemText primary={item.text} />
              </ListItemButton>
            )}
          </React.Fragment>
        ))}
      </List>
    </Box>
  );

  return (
    <>
      <AppBar position="fixed">
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={toggleDrawer}
            sx={{ mr: 2 }}
          >
            <MenuIcon />
          </IconButton>
          <Logo variant="h6" noWrap component={RouterLink} to="/" sx={{ color: 'inherit', textDecoration: 'none' }}>
            Critical Role Companion
          </Logo>
          <Box sx={{ display: { xs: 'none', sm: 'flex' }, gap: 1 }}>
            <Button
              component={RouterLink}
              to="/login"
              color="inherit"
            >
              Login
            </Button>
            <Button
              component={RouterLink}
              to="/signup"
              variant="contained"
              color="primary"
            >
              Sign Up
            </Button>
          </Box>
        </Toolbar>
      </AppBar>
      <StyledDrawer
        variant="temporary"
        anchor="left"
        open={drawerOpen}
        onClose={toggleDrawer}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
      >
        {drawer}
      </StyledDrawer>
    </>
  );
};

export default Navigation; 