import { Item } from '../types/lore';

// Mock item data
export const items: Item[] = [
  {
    id: 'whisper',
    name: 'Whisper',
    type: 'weapon',
    rarity: 'legendary',
    campaign: 1,
    imageUrl: '/images/items/whisper.jpg',
    description: 'A magical dagger that allows the wielder to teleport to the target after a successful hit.',
    currentOwner: 'Vax\'ildan',
    previousOwners: ['The Clasp'],
    firstAppearance: 'C1E25',
    attunement: true,
    properties: ['Teleportation', 'Magical Weapon', '+2 to attack and damage rolls'],
    spoilerLevel: 2
  },
  {
    id: 'staff-of-dunamancy',
    name: 'Staff of Dunamancy',
    type: 'staff',
    rarity: 'very rare',
    campaign: 2,
    imageUrl: '/images/items/staff-of-dunamancy.jpg',
    description: 'A staff that allows manipulation of time and gravity.',
    currentOwner: 'Essek Thelyss',
    previousOwners: ['Cerberus Assembly'],
    firstAppearance: 'C2E57',
    attunement: true,
    properties: ['Time Manipulation', 'Gravity Control', 'Spellcasting Focus'],
    spoilerLevel: 3
  },
  {
    id: 'cloak-of-displacement',
    name: 'Cloak of Displacement',
    type: 'wondrous item',
    rarity: 'rare',
    campaign: 2,
    imageUrl: '/images/items/cloak-of-displacement.jpg',
    description: 'A cloak that projects an illusion that makes the wearer appear to be standing in a place near their actual location.',
    currentOwner: 'Beauregard',
    previousOwners: [],
    firstAppearance: 'C2E12',
    attunement: true,
    properties: ['Displacement', 'Disadvantage on Attack Rolls'],
    spoilerLevel: 1
  },
  {
    id: 'hand-cone-of-clarity',
    name: 'Hand Cone of Clarity',
    type: 'wondrous item',
    rarity: 'uncommon',
    campaign: 3,
    imageUrl: '/images/items/hand-cone-of-clarity.jpg',
    description: 'A cone-shaped device that enhances the user\'s ability to detect magic and see invisible objects.',
    currentOwner: 'Orym',
    previousOwners: ['Keyleth'],
    firstAppearance: 'C3E1',
    attunement: false,
    properties: ['Detect Magic', 'See Invisibility', 'Enhanced Perception'],
    spoilerLevel: 1
  },
  {
    id: 'boots-of-haste',
    name: 'Boots of Haste',
    type: 'wondrous item',
    rarity: 'very rare',
    campaign: 1,
    imageUrl: '/images/items/boots-of-haste.jpg',
    description: 'Magical boots that allow the wearer to use the Haste spell on themselves once per day.',
    currentOwner: 'Vax\'ildan',
    previousOwners: [],
    firstAppearance: 'C1E33',
    attunement: true,
    properties: ['Cast Haste', 'Once per Day', 'Self Only'],
    spoilerLevel: 2
  }
];

// Filter items based on criteria
export const filterItems = (
  items: Item[],
  campaign?: number,
  type?: string,
  rarity?: string,
  attunement?: boolean,
  searchQuery?: string
): Item[] => {
  return items.filter(item => {
    // Filter by campaign
    if (campaign && item.campaign !== campaign) {
      return false;
    }

    // Filter by type
    if (type && type !== 'all' && item.type !== type) {
      return false;
    }

    // Filter by rarity
    if (rarity && rarity !== 'all' && item.rarity !== rarity) {
      return false;
    }

    // Filter by attunement
    if (attunement !== undefined && item.attunement !== attunement) {
      return false;
    }

    // Filter by search query
    if (searchQuery) {
      const query = searchQuery.toLowerCase();
      const matchName = item.name.toLowerCase().includes(query);
      const matchDescription = item.description.toLowerCase().includes(query);
      const matchOwner = item.currentOwner?.toLowerCase().includes(query) || false;
      if (!matchName && !matchDescription && !matchOwner) {
        return false;
      }
    }

    return true;
  });
};

// Get item by ID
export const getItemById = (id: string): Item | undefined => {
  return items.find(item => item.id === id);
};

// Get items by owner
export const getItemsByOwner = (owner: string): Item[] => {
  return items.filter(item => 
    item.currentOwner?.toLowerCase() === owner.toLowerCase() ||
    item.previousOwners?.some(prevOwner => 
      prevOwner.toLowerCase() === owner.toLowerCase()
    )
  );
};

// Get items by type
export const getItemsByType = (type: Item['type']): Item[] => {
  return items.filter(item => item.type === type);
};

// Get items by rarity
export const getItemsByRarity = (rarity: Item['rarity']): Item[] => {
  return items.filter(item => item.rarity === rarity);
};

// Get attunement items
export const getAttunementItems = (): Item[] => {
  return items.filter(item => item.attunement);
}; 