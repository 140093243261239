import React from 'react';
import { Card, CardContent, CardMedia, Typography, Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Character } from '../../types/lore';

interface CharacterCardProps {
  character: Character;
  onClick?: () => void;
}

const StyledCard = styled(Card)`
  cursor: pointer;
  transition: transform 0.2s ease-in-out;
  height: 100%;
  display: flex;
  flex-direction: column;

  &:hover {
    transform: scale(1.02);
  }
`;

const StyledCardMedia = styled(CardMedia)`
  height: 200px;
  background-size: cover;
  background-position: center;
`;

const StyledCardContent = styled(CardContent)`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(1)};
`;

const CharacterInfo = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(0.5)};
`;

const CharacterName = styled(Typography)`
  font-family: ${({ theme }) => theme.typography.fontFamily.display};
  font-weight: bold;
  font-size: ${({ theme }) => theme.typography.fontSize.lg}px;
  margin-bottom: ${({ theme }) => theme.spacing(1)};
`;

const CharacterDetail = styled(Typography)`
  color: ${({ theme }) => theme.palette.text.secondary};
  font-size: ${({ theme }) => theme.typography.fontSize.sm}px;
`;

const CharacterCard: React.FC<CharacterCardProps> = ({ character, onClick }) => {
  return (
    <StyledCard onClick={onClick}>
      <StyledCardMedia
        image={character.imageUrl || '/images/characters/placeholder.jpg'}
        title={character.name}
      />
      <StyledCardContent>
        <CharacterName variant="h5">{character.name}</CharacterName>
        <CharacterInfo>
          <CharacterDetail>
            {character.race} {character.class.join('/')}
          </CharacterDetail>
          <CharacterDetail>
            Level {character.level.join('/')}
          </CharacterDetail>
          <CharacterDetail>
            Campaign {character.campaign}
          </CharacterDetail>
        </CharacterInfo>
      </StyledCardContent>
    </StyledCard>
  );
};

export default CharacterCard; 