import { Episode } from '../types';

// Mock data for development
const mockEpisodes: Episode[] = [
  {
    id: 'C1E1',
    title: 'Arrival at Kraghammer',
    campaign: 1,
    episodeNumber: 1,
    summary: 'The adventurers begin their journey in the subterranean city of Kraghammer.',
    airDate: '2015-03-12',
    duration: 180,
    thumbnailUrl: '/images/episodes/C1E1.jpg',
    videoUrl: 'https://www.youtube.com/watch?v=i-p9lWIhcLQ',
    transcriptUrl: '/transcripts/C1E1.txt',
    spoilerLevel: 1,
    cast: ['Matt Mercer', 'Laura Bailey', 'Taliesin Jaffe', 'Ashley Johnson', 'Marisha Ray', 'Sam Riegel', 'Travis Willingham', 'Liam O\'Brien', 'Orion Acaba'],
    highlights: [
      {
        timestamp: 1200,
        description: 'The party arrives at the gates of Kraghammer'
      },
      {
        timestamp: 3600,
        description: 'First encounter with the mysterious creatures in the mines'
      }
    ]
  }
];

export const getEpisodes = async (): Promise<Episode[]> => {
  await new Promise(resolve => setTimeout(resolve, 1000));
  return mockEpisodes;
};

export const getEpisodeById = async (id: string): Promise<Episode | undefined> => {
  await new Promise(resolve => setTimeout(resolve, 1000));
  return mockEpisodes.find(episode => episode.id === id);
};

export const getEpisodesByCampaign = async (campaign: number): Promise<Episode[]> => {
  await new Promise(resolve => setTimeout(resolve, 1000));
  return mockEpisodes.filter(episode => episode.campaign === campaign);
};

export const getEpisodeCast = async (episodeId: string): Promise<Episode['cast']> => {
  await new Promise(resolve => setTimeout(resolve, 1000));
  const episode = mockEpisodes.find(ep => ep.id === episodeId);
  return episode?.cast || [];
};

export const filterEpisodes = (
  episodes: Episode[],
  filters: {
    campaign?: number;
    searchQuery?: string;
  }
): Episode[] => {
  return episodes.filter(episode => {
    if (filters.campaign && episode.campaign !== filters.campaign) {
      return false;
    }

    if (filters.searchQuery) {
      const query = filters.searchQuery.toLowerCase();
      const matchTitle = episode.title.toLowerCase().includes(query);
      const matchSummary = episode.summary.toLowerCase().includes(query);
      if (!matchTitle && !matchSummary) {
        return false;
      }
    }

    return true;
  });
};

// Get episodes related to a specific location
export const getRelatedEpisodes = async (locationId: string): Promise<Episode[]> => {
  // In a real implementation, this would fetch from an API
  // For now, return mock data based on location
  return mockEpisodes.filter(episode => 
    Math.random() > 0.5 // Randomly return episodes for demonstration
  );
};

// Get episode details
export const getEpisodeDetails = async (episodeId: string): Promise<Episode | null> => {
  const episode = mockEpisodes.find(ep => ep.id === episodeId);
  return episode || null;
};

// Search episodes
export const searchEpisodes = async (query: string): Promise<Episode[]> => {
  const searchLower = query.toLowerCase();
  return mockEpisodes.filter(episode =>
    episode.title.toLowerCase().includes(searchLower) ||
    episode.summary.toLowerCase().includes(searchLower)
  );
};

// Get episode highlights
export const getEpisodeHighlights = async (episodeId: string): Promise<Episode['highlights']> => {
  const episode = await getEpisodeDetails(episodeId);
  return episode?.highlights || [];
};

// Get episode transcript
export const getEpisodeTranscript = async (episodeId: string): Promise<string> => {
  // In a real implementation, this would fetch the transcript file
  return 'Episode transcript content...';
};

// Track episode progress
interface WatchProgress {
  episodeId: string;
  timestamp: number;
  completed: boolean;
}

const WATCH_PROGRESS_KEY = 'criticalrole_watch_progress';

export const saveWatchProgress = (progress: WatchProgress): void => {
  const savedProgress = getWatchProgress();
  const updatedProgress = {
    ...savedProgress,
    [progress.episodeId]: progress,
  };
  localStorage.setItem(WATCH_PROGRESS_KEY, JSON.stringify(updatedProgress));
};

export const getWatchProgress = (): Record<string, WatchProgress> => {
  const savedProgress = localStorage.getItem(WATCH_PROGRESS_KEY);
  return savedProgress ? JSON.parse(savedProgress) : {};
};

export const getEpisodeProgress = (episodeId: string): WatchProgress | null => {
  const savedProgress = getWatchProgress();
  return savedProgress[episodeId] || null;
};

// Episode notes/bookmarks
interface EpisodeNote {
  id: string;
  episodeId: string;
  timestamp: number;
  content: string;
  createdAt: number;
}

const EPISODE_NOTES_KEY = 'criticalrole_episode_notes';

export const saveEpisodeNote = (note: Omit<EpisodeNote, 'id' | 'createdAt'>): EpisodeNote => {
  const savedNotes = getEpisodeNotes();
  const newNote: EpisodeNote = {
    ...note,
    id: Math.random().toString(36).substr(2, 9),
    createdAt: Date.now(),
  };
  savedNotes.push(newNote);
  localStorage.setItem(EPISODE_NOTES_KEY, JSON.stringify(savedNotes));
  return newNote;
};

export const getEpisodeNotes = (): EpisodeNote[] => {
  const savedNotes = localStorage.getItem(EPISODE_NOTES_KEY);
  return savedNotes ? JSON.parse(savedNotes) : [];
};

export const getEpisodeNotesByEpisode = (episodeId: string): EpisodeNote[] => {
  const allNotes = getEpisodeNotes();
  return allNotes.filter(note => note.episodeId === episodeId);
};

export const deleteEpisodeNote = (noteId: string): void => {
  const savedNotes = getEpisodeNotes();
  const updatedNotes = savedNotes.filter(note => note.id !== noteId);
  localStorage.setItem(EPISODE_NOTES_KEY, JSON.stringify(updatedNotes));
};

// Get latest episodes
export const getLatestEpisodes = async (): Promise<Episode[]> => {
  // In a real implementation, this would fetch from an API
  // For now, return mock data sorted by air date
  return [...mockEpisodes].sort((a, b) => 
    new Date(b.airDate).getTime() - new Date(a.airDate).getTime()
  );
}; 