import React from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import styled from '@emotion/styled';
import { motion } from 'framer-motion';
import { customTheme } from '../../../theme/theme';
import { getFactionById, getAlliedFactions, getEnemyFactions } from '../../../services/factions';

const Container = styled.div`
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: ${props => props.theme.spacing(4)};
`;

const BackButton = styled.button`
  background: none;
  border: none;
  color: ${props => props.theme.palette.text.secondary};
  font-family: ${props => props.theme.typography.fontFamily};
  font-size: ${props => props.theme.typography.body1.fontSize};
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: ${props => props.theme.spacing(1)};
  margin-bottom: ${props => props.theme.spacing(4)};
  padding: 0;

  &:hover {
    color: ${props => props.theme.palette.text.primary};
  }
`;

const Content = styled(motion.div)`
  display: grid;
  grid-template-columns: 1fr 2fr;
  gap: ${props => props.theme.spacing(4)};

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

const ImageSection = styled.div`
  position: sticky;
  top: ${props => props.theme.spacing(4)};
`;

const FactionImage = styled.div<{ imageUrl?: string }>`
  width: 100%;
  height: 400px;
  background-image: ${props => props.imageUrl ? `url(${props.imageUrl})` : 'none'};
  background-color: ${props => props.theme.palette.background.default};
  background-size: cover;
  background-position: center;
  border-radius: ${props => props.theme.shape.borderRadius}px;
  border: 1px solid ${props => props.theme.palette.primary.main}33;
  margin-bottom: ${props => props.theme.spacing(3)};
`;

const InfoSection = styled.div``;

const Header = styled.div`
  margin-bottom: ${props => props.theme.spacing(4)};
`;

const Title = styled.h1`
  font-family: ${props => props.theme.typography.fontFamily};
  font-size: ${props => props.theme.typography.h2.fontSize};
  color: ${props => props.theme.palette.text.primary};
  margin-bottom: ${props => props.theme.spacing(2)};
`;

const MetaInfo = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: ${props => props.theme.spacing(2)};
  margin-bottom: ${props => props.theme.spacing(3)};
`;

const MetaItem = styled.span<{ variant?: 'type' | 'default' }>`
  font-size: ${props => props.theme.typography.body1.fontSize};
  color: ${props => props.theme.palette.text.primary};
  background: ${props => props.variant === 'type' ? 
    `${props.theme.palette.primary.main}33` : 
    `${props.theme.palette.background.default}CC`};
  padding: ${props => props.theme.spacing(1)} ${props => props.theme.spacing(2)};
  border-radius: ${props => props.theme.shape.borderRadius}px;
  border: 1px solid ${props => props.theme.palette.primary.main}33;
`;

const Description = styled.p`
  font-size: ${props => props.theme.typography.body1.fontSize};
  color: ${props => props.theme.palette.text.primary};
  line-height: 1.6;
  margin-bottom: ${props => props.theme.spacing(4)};
`;

const Section = styled.div`
  margin-bottom: ${props => props.theme.spacing(4)};
`;

const SectionTitle = styled.h2`
  font-family: ${props => props.theme.typography.fontFamily};
  font-size: ${props => props.theme.typography.h4.fontSize};
  color: ${props => props.theme.palette.text.primary};
  margin-bottom: ${props => props.theme.spacing(2)};
`;

const MemberList = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${props => props.theme.spacing(2)};
`;

const MemberCard = styled.div`
  display: flex;
  align-items: center;
  gap: ${props => props.theme.spacing(2)};
  padding: ${props => props.theme.spacing(2)};
  background: ${props => props.theme.palette.background.default}CC;
  border-radius: ${props => props.theme.shape.borderRadius}px;
  border: 1px solid ${props => props.theme.palette.primary.main}33;
`;

const MemberName = styled.span`
  font-size: ${props => props.theme.typography.body1.fontSize};
  color: ${props => props.theme.palette.text.primary};
`;

const RelationshipGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: ${props => props.theme.spacing(2)};
`;

const RelationshipCard = styled.div<{ type: 'ally' | 'enemy' }>`
  padding: ${props => props.theme.spacing(2)};
  background: ${props => props.type === 'ally' ? 
    `${props.theme.palette.success.main}11` : 
    `${props.theme.palette.error.main}11`};
  border-radius: ${props => props.theme.shape.borderRadius}px;
  border: 1px solid ${props => props.type === 'ally' ? 
    props.theme.palette.success.main : 
    props.theme.palette.error.main}33;
`;

const TerritoryList = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: ${props => props.theme.spacing(2)};
`;

const TerritoryItem = styled.div`
  padding: ${props => props.theme.spacing(1)} ${props => props.theme.spacing(2)};
  background: ${props => props.theme.palette.background.default}CC;
  border-radius: ${props => props.theme.shape.borderRadius}px;
  border: 1px solid ${props => props.theme.palette.primary.main}33;
  font-size: ${props => props.theme.typography.body1.fontSize};
  color: ${props => props.theme.palette.text.primary};
`;

const NotFound = styled.div`
  text-align: center;
  padding: ${props => props.theme.spacing(4)};
  color: ${props => props.theme.palette.text.secondary};
  font-family: ${props => props.theme.typography.fontFamily};
  font-size: ${props => props.theme.typography.h5.fontSize};
`;

const FactionDetail: React.FC = () => {
  const { factionId } = useParams<{ factionId: string }>();
  const navigate = useNavigate();
  const faction = factionId ? getFactionById(factionId) : undefined;
  const alliedFactions = factionId ? getAlliedFactions(factionId) : [];
  const enemyFactions = factionId ? getEnemyFactions(factionId) : [];

  if (!faction) {
    return (
      <Container>
        <BackButton onClick={() => navigate('/lore/factions')}>
          ← Back to Factions
        </BackButton>
        <NotFound>Faction not found</NotFound>
      </Container>
    );
  }

  return (
    <Container>
      <BackButton onClick={() => navigate('/lore/factions')}>
        ← Back to Factions
      </BackButton>
      
      <Content
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: -20 }}
        transition={{ duration: 0.3 }}
      >
        <ImageSection>
          <FactionImage imageUrl={faction.imageUrl} />
          <MetaInfo>
            <MetaItem variant="type">
              {faction.type.charAt(0).toUpperCase() + faction.type.slice(1)}
            </MetaItem>
            <MetaItem>Campaign {faction.campaign}</MetaItem>
            <MetaItem>{faction.status}</MetaItem>
          </MetaInfo>
        </ImageSection>

        <InfoSection>
          <Header>
            <Title>{faction.name}</Title>
            <Description>{faction.description}</Description>
          </Header>

          {faction.leader && (
            <Section>
              <SectionTitle>Leadership</SectionTitle>
              <MemberCard>
                <MemberName>{faction.leader}</MemberName>
              </MemberCard>
            </Section>
          )}

          {faction.members && faction.members.length > 0 && (
            <Section>
              <SectionTitle>Notable Members</SectionTitle>
              <MemberList>
                {faction.members.map((member, index) => (
                  <MemberCard key={index}>
                    <MemberName>{member}</MemberName>
                  </MemberCard>
                ))}
              </MemberList>
            </Section>
          )}

          {faction.territory && faction.territory.length > 0 && (
            <Section>
              <SectionTitle>Territory</SectionTitle>
              <TerritoryList>
                {faction.territory.map((territory, index) => (
                  <TerritoryItem key={index}>
                    {territory}
                  </TerritoryItem>
                ))}
              </TerritoryList>
            </Section>
          )}

          <Section>
            <SectionTitle>Relationships</SectionTitle>
            <RelationshipGrid>
              {alliedFactions.length > 0 && (
                <Section>
                  <SectionTitle>Allies</SectionTitle>
                  {alliedFactions.map((ally) => (
                    <RelationshipCard key={ally.id} type="ally">
                      <MemberName>{ally.name}</MemberName>
                    </RelationshipCard>
                  ))}
                </Section>
              )}
              {enemyFactions.length > 0 && (
                <Section>
                  <SectionTitle>Enemies</SectionTitle>
                  {enemyFactions.map((enemy) => (
                    <RelationshipCard key={enemy.id} type="enemy">
                      <MemberName>{enemy.name}</MemberName>
                    </RelationshipCard>
                  ))}
                </Section>
              )}
            </RelationshipGrid>
          </Section>

          <Section>
            <SectionTitle>First Appearance</SectionTitle>
            <MetaItem>Episode {faction.firstAppearance}</MetaItem>
          </Section>
        </InfoSection>
      </Content>
    </Container>
  );
};

export default FactionDetail; 