import { Monster, MonsterListItem } from '../types/monster';

const BASE_URL = import.meta.env.VITE_API_URL || 'https://www.dnd5eapi.co/api';

export interface APIResponse<T> {
  count: number;
  results: T[];
}

export interface APIMonsterListItem {
  index: string;
  name: string;
  url: string;
}

export interface APIMonster {
  index: string;
  name: string;
  size: string;
  type: string;
  alignment: string;
  armor_class: [{ value: number, type: string }];
  hit_points: number;
  hit_dice: string;
  speed: {
    walk?: string;
    fly?: string;
    swim?: string;
    climb?: string;
    burrow?: string;
  };
  strength: number;
  dexterity: number;
  constitution: number;
  intelligence: number;
  wisdom: number;
  charisma: number;
  proficiencies: {
    value: number;
    proficiency: {
      index: string;
      name: string;
      url: string;
    };
  }[];
  damage_vulnerabilities: string[];
  damage_resistances: string[];
  damage_immunities: string[];
  condition_immunities: {
    index: string;
    name: string;
    url: string;
  }[];
  senses: Record<string, string>;
  languages: string;
  challenge_rating: number;
  xp: number;
  special_abilities?: {
    name: string;
    desc: string;
  }[];
  actions: {
    name: string;
    desc: string;
    damage?: {
      damage_type: {
        index: string;
        name: string;
        url: string;
      };
      damage_dice: string;
    }[];
  }[];
  legendary_actions?: {
    name: string;
    desc: string;
  }[];
}

const convertSpeed = (speedObj: APIMonster['speed']) => {
  const result: Monster['speed'] = {};
  if (!speedObj) return result;
  
  Object.entries(speedObj).forEach(([key, value]) => {
    if (value) {
      result[key as keyof Monster['speed']] = parseInt(value.replace(/\D/g, ''));
    }
  });
  return result;
};

const convertToMonster = (apiMonster: APIMonster): Monster => {
  try {
    return {
      id: apiMonster.index,
      name: apiMonster.name,
      size: apiMonster.size as Monster['size'],
      type: apiMonster.type,
      alignment: apiMonster.alignment,
      armorClass: apiMonster.armor_class?.[0]?.value ?? 10,
      hitPoints: apiMonster.hit_points,
      speed: convertSpeed(apiMonster.speed),
      stats: {
        strength: apiMonster.strength ?? 10,
        dexterity: apiMonster.dexterity ?? 10,
        constitution: apiMonster.constitution ?? 10,
        intelligence: apiMonster.intelligence ?? 10,
        wisdom: apiMonster.wisdom ?? 10,
        charisma: apiMonster.charisma ?? 10,
      },
      savingThrows: (apiMonster.proficiencies || [])
        .filter(p => p?.proficiency?.index?.startsWith('saving-throw'))
        .reduce((acc, curr) => ({
          ...acc,
          [curr.proficiency.index.replace('saving-throw-', '')]: curr.value
        }), {}),
      skills: (apiMonster.proficiencies || [])
        .filter(p => p?.proficiency?.index?.startsWith('skill'))
        .reduce((acc, curr) => ({
          ...acc,
          [curr.proficiency.name.replace('Skill: ', '')]: curr.value
        }), {}),
      damageResistances: apiMonster.damage_resistances || [],
      damageImmunities: apiMonster.damage_immunities || [],
      conditionImmunities: (apiMonster.condition_immunities || []).map(ci => ci?.name || ''),
      senses: Object.entries(apiMonster.senses || {}).reduce((acc, [key, value]) => ({
        ...acc,
        [key]: parseInt(value?.toString() || '0')
      }), {}),
      languages: (apiMonster.languages || '').split(',').map(l => l.trim()).filter(Boolean),
      challengeRating: apiMonster.challenge_rating ?? 0,
      xp: apiMonster.xp ?? 0,
      abilities: (apiMonster.special_abilities || []).map(ability => ({
        name: ability?.name || '',
        description: ability?.desc || '',
      })),
      actions: (apiMonster.actions || []).map(action => ({
        name: action?.name || '',
        description: action?.desc || '',
        damage: action?.damage?.[0] ? {
          type: action.damage[0].damage_type?.name || 'none',
          diceCount: parseInt(action.damage[0].damage_dice?.split('d')?.[0] || '0'),
          diceValue: parseInt(action.damage[0].damage_dice?.split('d')?.[1] || '0'),
        } : undefined,
      })),
      legendaryActions: (apiMonster.legendary_actions || []).map(action => ({
        name: action?.name || '',
        description: action?.desc || '',
      })),
      sourceBook: 'Monster Manual',
      spoilerLevel: 0,
    };
  } catch (error) {
    console.error('Error converting monster:', error);
    throw new Error('Failed to convert monster data');
  }
};

const convertToMonsterListItem = (apiMonster: APIMonsterListItem): MonsterListItem => ({
  id: apiMonster.index || '',
  name: apiMonster.name || 'Unknown Monster',
  type: '', // Will be filled when fetching full monster details
  challengeRating: 0, // Will be filled when fetching full monster details
  size: 'Medium', // Will be filled when fetching full monster details
  sourceBook: 'Monster Manual',
  spoilerLevel: 0,
});

export const dndApi = {
  async getMonsterList(): Promise<MonsterListItem[]> {
    try {
      const response = await fetch(`${BASE_URL}/monsters`);
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data: APIResponse<APIMonsterListItem> = await response.json();
      return Promise.all(
        data.results.map(async (monster) => {
          try {
            const fullMonster = await this.getMonster(monster.index);
            return {
              ...convertToMonsterListItem(monster),
              type: fullMonster.type,
              challengeRating: fullMonster.challengeRating,
              size: fullMonster.size,
            };
          } catch (error) {
            console.error(`Error fetching details for monster ${monster.name}:`, error);
            return convertToMonsterListItem(monster);
          }
        })
      );
    } catch (error) {
      console.error('Error fetching monster list:', error);
      throw error;
    }
  },

  async getMonster(id: string): Promise<Monster> {
    try {
      const response = await fetch(`${BASE_URL}/monsters/${id}`);
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data: APIMonster = await response.json();
      return convertToMonster(data);
    } catch (error) {
      console.error(`Error fetching monster ${id}:`, error);
      throw error;
    }
  },

  async searchMonsters(query: string): Promise<MonsterListItem[]> {
    try {
      const allMonsters = await this.getMonsterList();
      return allMonsters.filter(monster => 
        monster.name.toLowerCase().includes(query.toLowerCase())
      );
    } catch (error) {
      console.error('Error searching monsters:', error);
      throw error;
    }
  },
}; 