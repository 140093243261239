import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, Typography, Box, Paper } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Theme } from '@mui/material/styles';
import { Item } from '../../../types/lore';
import { items, filterItems } from '../../../services/items';

const Title = styled(Typography)<{ theme?: Theme }>`
  font-family: ${({ theme }) => theme.typography.fontFamily};
  font-size: ${({ theme }) => theme.typography.h2.fontSize}px;
  color: ${({ theme }) => theme.palette.text.primary};
  margin-bottom: ${({ theme }) => theme.spacing(4)};
  text-align: center;
`;

const FilterBar = styled(Box)<{ theme?: Theme }>`
  display: flex;
  gap: ${({ theme }) => theme.spacing(2)};
  margin-bottom: ${({ theme }) => theme.spacing(4)};
  flex-wrap: wrap;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const Select = styled('select')<{ theme?: Theme }>`
  flex: 1;
  min-width: 200px;
  background: ${({ theme }) => theme.palette.background.default};
  border: 1px solid ${({ theme }) => theme.palette.primary.main}33;
  border-radius: ${({ theme }) => theme.shape.borderRadius}px;
  padding: ${({ theme }) => theme.spacing(2)};
  color: ${({ theme }) => theme.palette.text.primary};
  font-family: ${({ theme }) => theme.typography.fontFamily};

  &:focus {
    outline: none;
    border-color: ${({ theme }) => theme.palette.primary.main};
  }

  option {
    background: ${({ theme }) => theme.palette.background.default};
    color: ${({ theme }) => theme.palette.text.primary};
  }
`;

const SearchInput = styled('input')<{ theme?: Theme }>`
  flex: 1;
  min-width: 200px;
  background: ${({ theme }) => theme.palette.background.default};
  border: 1px solid ${({ theme }) => theme.palette.primary.main}33;
  border-radius: ${({ theme }) => theme.shape.borderRadius}px;
  padding: ${({ theme }) => theme.spacing(2)};
  color: ${({ theme }) => theme.palette.text.primary};
  font-family: ${({ theme }) => theme.typography.fontFamily};

  &:focus {
    outline: none;
    border-color: ${({ theme }) => theme.palette.primary.main};
  }
`;

const ItemsGrid = styled(Box)<{ theme?: Theme }>`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  gap: ${({ theme }) => theme.spacing(4)};
`;

const ItemCard = styled(Paper)<{ theme?: Theme }>`
  background: ${({ theme }) => theme.palette.background.paper}CC;
  border-radius: ${({ theme }) => theme.shape.borderRadius}px;
  border: 1px solid ${({ theme }) => theme.palette.primary.main}33;
  overflow: hidden;
  cursor: pointer;
  transition: all 0.2s ease-in-out;

  &:hover {
    border-color: ${({ theme }) => theme.palette.primary.main};
    transform: translateY(-4px);
  }

  &:active {
    transform: scale(0.98);
  }
`;

const ItemImage = styled(Box)<{ theme?: Theme; imageUrl?: string }>`
  height: 200px;
  background-image: ${({ imageUrl }) => imageUrl ? `url(${imageUrl})` : 'none'};
  background-color: ${({ theme }) => theme.palette.background.default};
  background-size: cover;
  background-position: center;
  border-bottom: 1px solid ${({ theme }) => theme.palette.primary.main}33;
  position: relative;
`;

const ItemInfo = styled(Box)<{ theme?: Theme }>`
  padding: ${({ theme }) => theme.spacing(2)};
`;

const ItemName = styled(Typography)<{ theme?: Theme }>`
  font-family: ${({ theme }) => theme.typography.fontFamily};
  font-size: ${({ theme }) => theme.typography.h6.fontSize}px;
  color: ${({ theme }) => theme.palette.text.primary};
  margin-bottom: ${({ theme }) => theme.spacing(1)};
`;

const ItemMeta = styled(Typography)<{ theme?: Theme }>`
  font-size: ${({ theme }) => theme.typography.body2.fontSize}px;
  color: ${({ theme }) => theme.palette.text.secondary};
  margin-bottom: ${({ theme }) => theme.spacing(1)};
`;

const ItemDescription = styled(Typography)<{ theme?: Theme }>`
  font-size: ${({ theme }) => theme.typography.body2.fontSize}px;
  color: ${({ theme }) => theme.palette.text.secondary};
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
`;

const Items: React.FC = () => {
  const navigate = useNavigate();
  const [filteredItems, setFilteredItems] = useState<Item[]>(items);
  const [filter, setFilter] = useState({
    type: 'all',
    rarity: 'all',
    search: '',
  });

  useEffect(() => {
    const type = filter.type === 'all' ? undefined : filter.type;
    const rarity = filter.rarity === 'all' ? undefined : filter.rarity;
    const searchQuery = filter.search || undefined;
    
    const filtered = filterItems(items, undefined, type, rarity, undefined, searchQuery);
    setFilteredItems(filtered);
  }, [filter]);

  const handleFilterChange = (key: keyof typeof filter, value: string) => {
    setFilter(prev => ({ ...prev, [key]: value }));
  };

  return (
    <Container maxWidth="lg" sx={{ py: 4 }}>
      <Title variant="h1">Magical Items & Artifacts</Title>
      <FilterBar>
        <Select
          value={filter.type}
          onChange={(e) => handleFilterChange('type', e.target.value)}
        >
          <option value="all">All Types</option>
          <option value="weapon">Weapons</option>
          <option value="armor">Armor</option>
          <option value="accessory">Accessories</option>
          <option value="vestige">Vestiges</option>
          <option value="artifact">Artifacts</option>
        </Select>
        <Select
          value={filter.rarity}
          onChange={(e) => handleFilterChange('rarity', e.target.value)}
        >
          <option value="all">All Rarities</option>
          <option value="common">Common</option>
          <option value="uncommon">Uncommon</option>
          <option value="rare">Rare</option>
          <option value="very-rare">Very Rare</option>
          <option value="legendary">Legendary</option>
          <option value="artifact">Artifact</option>
        </Select>
        <SearchInput
          type="text"
          placeholder="Search items..."
          value={filter.search}
          onChange={(e) => handleFilterChange('search', e.target.value)}
        />
      </FilterBar>
      <ItemsGrid>
        {filteredItems.map((item) => (
          <ItemCard
            key={item.id}
            onClick={() => navigate(`/lore/items/${item.id}`)}
            elevation={1}
          >
            <ItemImage imageUrl={item.imageUrl} />
            <ItemInfo>
              <ItemName variant="h6">{item.name}</ItemName>
              <ItemMeta variant="body2">
                {item.type} • {item.rarity}
                {item.attunement && ' • Requires Attunement'}
              </ItemMeta>
              <ItemDescription variant="body2">{item.description}</ItemDescription>
            </ItemInfo>
          </ItemCard>
        ))}
      </ItemsGrid>
    </Container>
  );
};

export default Items;