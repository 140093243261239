import { Event } from '../types/lore';

// Mock event data
export const events: Event[] = [
  {
    id: 'chroma-conclave-attack',
    name: 'Attack of the Chroma Conclave',
    type: 'battle',
    campaign: 1,
    imageUrl: '/images/events/chroma-conclave.jpg',
    description: 'The devastating attack on Emon and other major cities by the Chroma Conclave, a group of ancient chromatic dragons.',
    date: '812 P.D.',
    location: 'Emon',
    participants: ['Vox Machina', 'Thordak', 'Raishan', 'Umbrasyl', 'Vorugal'],
    consequences: [
      'Fall of Emon',
      'Devastation of multiple cities',
      'Formation of the Council of Tal\'Dorei in exile'
    ],
    outcome: 'Initial victory for the Chroma Conclave',
    firstAppearance: 'C1E39',
    relatedEvents: ['conclave-defeat'],
    relatedLocations: ['emon', 'whitestone'],
    relatedFactions: ['Chroma Conclave', "Tal'Dorei Council"],
    spoilerLevel: 3
  },
  {
    id: 'divergence',
    name: 'The Divergence',
    type: 'historical',
    campaign: 1,
    imageUrl: '/images/events/divergence.jpg',
    description: 'The cataclysmic event that ended the Age of Arcanum, when the gods banished the Betrayer Gods and erected the Divine Gate.',
    date: 'Unknown',
    location: 'Exandria',
    participants: ['Prime Deities', 'Betrayer Gods'],
    consequences: [
      'Creation of the Divine Gate',
      'End of direct divine intervention',
      'Reshaping of Exandria'
    ],
    outcome: 'Victory for the Prime Deities',
    firstAppearance: 'C1E1',
    relatedEvents: ['calamity'],
    relatedLocations: ['vasselheim'],
    relatedFactions: ['Prime Deities', 'Betrayer Gods'],
    spoilerLevel: 1
  },
  {
    id: 'mighty-nein-formation',
    name: 'Formation of the Mighty Nein',
    type: 'group',
    campaign: 2,
    imageUrl: '/images/events/mighty-nein.jpg',
    description: 'The coming together of seven unlikely individuals who would become known as the Mighty Nein.',
    date: '835 P.D.',
    location: 'Trostenwald',
    participants: ['Caleb Widogast', 'Nott the Brave', 'Beauregard Lionett', 'Jester Lavorre', 'Fjord', 'Mollymauk Tealeaf', 'Yasha Nydoorin'],
    consequences: [
      'Formation of a new adventuring party',
      'Beginning of major changes in Wildemount'
    ],
    outcome: 'Formation of the Mighty Nein',
    firstAppearance: 'C2E1',
    relatedEvents: ['war-outbreak'],
    relatedLocations: ['trostenwald', 'zadash'],
    relatedFactions: ['Mighty Nein'],
    spoilerLevel: 1
  },
  {
    id: 'whitestone-liberation',
    name: 'Liberation of Whitestone',
    type: 'battle',
    campaign: 1,
    imageUrl: '/images/events/whitestone-liberation.jpg',
    description: 'The successful rebellion against the Briarwoods\' rule over Whitestone, led by Vox Machina and the resistance.',
    date: '810 P.D.',
    location: 'Whitestone',
    participants: ['Vox Machina', 'Cassandra de Rolo', 'Lady Delilah Briarwood', 'Lord Sylas Briarwood'],
    consequences: [
      'Freedom of Whitestone',
      'Return of the de Rolo family',
      'Destruction of the Ziggurat ritual'
    ],
    outcome: 'Victory for Vox Machina and the Resistance',
    firstAppearance: 'C1E24',
    relatedEvents: ['briarwood-arc'],
    relatedLocations: ['whitestone', 'whitestone-castle'],
    relatedFactions: ['Vox Machina', 'Briarwoods', 'Whitestone Rebellion'],
    spoilerLevel: 2
  },
  {
    id: 'peace-treaty',
    name: 'Dynasty-Empire Peace Treaty',
    type: 'political',
    campaign: 2,
    imageUrl: '/images/events/peace-treaty.jpg',
    description: 'The historic peace negotiations between the Kryn Dynasty and the Dwendalian Empire, mediated by the Mighty Nein.',
    date: '836 P.D.',
    location: 'Rexxentrum',
    participants: ['King Dwendal', 'Bright Queen Leylas Kryn', 'The Mighty Nein'],
    consequences: [
      'End of the war',
      'Establishment of trade routes',
      'Cultural exchange programs'
    ],
    outcome: 'Successful peace treaty signed',
    firstAppearance: 'C2E97',
    relatedEvents: ['war-outbreak', 'beacon-theft'],
    relatedLocations: ['rexxentrum', 'rosohna'],
    relatedFactions: ['Kryn Dynasty', 'Dwendalian Empire', 'Mighty Nein'],
    spoilerLevel: 3
  }
];

// Filter events based on criteria
export const filterEvents = (
  events: Event[],
  campaign?: number,
  type?: string,
  startDate?: string,
  endDate?: string,
  searchQuery?: string
): Event[] => {
  return events.filter(event => {
    // Filter by campaign
    if (campaign && event.campaign !== campaign) {
      return false;
    }

    // Filter by type
    if (type && type !== 'all' && event.type !== type) {
      return false;
    }

    // Filter by date range
    if (event.date) {
      if (startDate && event.date < startDate) {
        return false;
      }
      if (endDate && event.date > endDate) {
        return false;
      }
    }

    // Filter by search query
    if (searchQuery) {
      const query = searchQuery.toLowerCase();
      const matchName = event.name.toLowerCase().includes(query);
      const matchDescription = event.description.toLowerCase().includes(query);
      const matchLocation = event.location?.toLowerCase().includes(query) || false;
      const matchParticipants = event.participants.some(p => 
        p.toLowerCase().includes(query)
      );
      if (!matchName && !matchDescription && !matchLocation && !matchParticipants) {
        return false;
      }
    }

    return true;
  });
};

// Get event by ID
export const getEventById = (id: string): Event | undefined => {
  return events.find(event => event.id === id);
};

// Get events by type
export const getEventsByType = (type: Event['type']): Event[] => {
  return events.filter(event => event.type === type);
};

// Get events by location
export const getEventsByLocation = (location: string): Event[] => {
  return events.filter(event => 
    event.location?.toLowerCase() === location.toLowerCase() ||
    event.relatedLocations?.some(loc => loc.toLowerCase() === location.toLowerCase())
  );
};

// Get events by participant
export const getEventsByParticipant = (participant: string): Event[] => {
  return events.filter(event => 
    event.participants?.some(p => p.toLowerCase() === participant.toLowerCase())
  );
};

// Get events by faction
export const getEventsByFaction = (faction: string): Event[] => {
  return events.filter(event => 
    event.relatedFactions?.some(f => f.toLowerCase() === faction.toLowerCase())
  );
};

// Get related events
export const getRelatedEvents = (eventId: string): Event[] => {
  const event = getEventById(eventId);
  if (!event?.relatedEvents) return [];
  
  return events.filter(e => 
    event.relatedEvents?.includes(e.id)
  );
};

// Get events by date range
export const getEventsByDateRange = (events: Event[], startDate: string, endDate: string): Event[] => {
  return events.filter(event => 
    event.date ? event.date >= startDate && event.date <= endDate : false
  );
};

export const filterEventsByDate = (events: Event[], startDate?: string, endDate?: string): Event[] => {
  return events.filter(event => {
    if (!event.date) return false;
    
    if (startDate && event.date < startDate) {
      return false;
    }

    if (endDate && event.date > endDate) {
      return false;
    }

    return true;
  });
};

export const getEventsBetweenDates = (events: Event[], startDate: string, endDate: string): Event[] => {
  return events.filter(event => 
    event.date && event.date >= startDate && event.date <= endDate
  );
}; 