import React from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import styled from '@emotion/styled';
import { motion } from 'framer-motion';
import { customTheme } from '../../../theme/theme';
import { getItemById } from '../../../services/items';

const Container = styled.div`
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: ${props => props.theme.spacing(4)};
`;

const BackButton = styled.button`
  background: none;
  border: none;
  color: ${props => props.theme.palette.text.secondary};
  font-family: ${props => props.theme.typography.fontFamily};
  font-size: ${props => props.theme.typography.fontSize}px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: ${props => props.theme.spacing(1)};
  margin-bottom: ${props => props.theme.spacing(4)};
  padding: 0;

  &:hover {
    color: ${props => props.theme.palette.text.primary};
  }
`;

const Content = styled(motion.div)`
  display: grid;
  grid-template-columns: 1fr 2fr;
  gap: ${props => props.theme.spacing(4)};

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

const ImageSection = styled.div`
  position: sticky;
  top: ${props => props.theme.spacing(4)};
`;

const ItemImage = styled.div<{ imageUrl?: string }>`
  width: 100%;
  height: 400px;
  background-image: ${props => props.imageUrl ? `url(${props.imageUrl})` : 'none'};
  background-color: ${props => props.theme.palette.background.default};
  background-size: cover;
  background-position: center;
  border-radius: ${props => props.theme.shape.borderRadius}px;
  border: 1px solid ${props => props.theme.palette.primary.main}33;
  margin-bottom: ${props => props.theme.spacing(3)};
`;

const InfoSection = styled.div``;

const Header = styled.div`
  margin-bottom: ${props => props.theme.spacing(4)};
`;

const Title = styled.h1`
  font-family: ${props => props.theme.typography.fontFamily};
  font-size: ${props => props.theme.typography.h1.fontSize};
  color: ${props => props.theme.palette.text.primary};
  margin-bottom: ${props => props.theme.spacing(2)};
`;

const MetaInfo = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: ${props => props.theme.spacing(2)};
  margin-bottom: ${props => props.theme.spacing(3)};
`;

const MetaItem = styled.span<{ variant?: 'rarity' | 'default' }>`
  font-size: ${props => props.theme.typography.body1.fontSize};
  color: ${props => props.theme.palette.text.primary};
  background: ${props => props.variant === 'rarity' ? 
    `${props.theme.palette.primary.main}33` : 
    `${props.theme.palette.background.default}CC`};
  padding: ${props => props.theme.spacing(1)} ${props => props.theme.spacing(2)};
  border-radius: ${props => props.theme.shape.borderRadius}px;
  border: 1px solid ${props => props.theme.palette.primary.main}33;
`;

const Description = styled.p`
  font-size: ${props => props.theme.typography.body1.fontSize};
  color: ${props => props.theme.palette.text.primary};
  line-height: 1.6;
  margin-bottom: ${props => props.theme.spacing(4)};
`;

const Section = styled.div`
  margin-bottom: ${props => props.theme.spacing(4)};
`;

const SectionTitle = styled.h2`
  font-family: ${props => props.theme.typography.fontFamily};
  font-size: ${props => props.theme.typography.h2.fontSize};
  color: ${props => props.theme.palette.text.primary};
  margin-bottom: ${props => props.theme.spacing(2)};
`;

const OwnershipHistory = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${props => props.theme.spacing(2)};
`;

const OwnershipEntry = styled.div`
  display: flex;
  align-items: center;
  gap: ${props => props.theme.spacing(2)};
  padding: ${props => props.theme.spacing(2)};
  background: ${props => props.theme.palette.background.default}CC;
  border-radius: ${props => props.theme.shape.borderRadius}px;
  border: 1px solid ${props => props.theme.palette.primary.main}33;
`;

const OwnerName = styled.span`
  font-size: ${props => props.theme.typography.body1.fontSize};
  color: ${props => props.theme.palette.text.primary};
`;

const NotFound = styled.div`
  text-align: center;
  padding: ${props => props.theme.spacing(4)};
  color: ${props => props.theme.palette.text.secondary};
  font-family: ${props => props.theme.typography.fontFamily};
  font-size: ${props => props.theme.typography.h4.fontSize};
`;

const ItemDetail: React.FC = () => {
  const { itemId } = useParams<{ itemId: string }>();
  const navigate = useNavigate();
  const item = itemId ? getItemById(itemId) : undefined;

  if (!item) {
    return (
      <Container>
        <BackButton onClick={() => navigate('/lore/items')}>
          ← Back to Items
        </BackButton>
        <NotFound>Item not found</NotFound>
      </Container>
    );
  }

  return (
    <Container>
      <BackButton onClick={() => navigate('/lore/items')}>
        ← Back to Items
      </BackButton>
      
      <Content
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: -20 }}
        transition={{ duration: 0.3 }}
      >
        <ImageSection>
          <ItemImage imageUrl={item.imageUrl} />
          <MetaInfo>
            <MetaItem variant="rarity">
              {item.rarity.charAt(0).toUpperCase() + item.rarity.slice(1)}
            </MetaItem>
            <MetaItem>{item.type}</MetaItem>
            {item.attunement && <MetaItem>Requires Attunement</MetaItem>}
          </MetaInfo>
        </ImageSection>

        <InfoSection>
          <Header>
            <Title>{item.name}</Title>
            <Description>{item.description}</Description>
          </Header>

          <Section>
            <SectionTitle>Current Owner</SectionTitle>
            <OwnershipEntry>
              <OwnerName>{item.currentOwner || 'Unknown'}</OwnerName>
            </OwnershipEntry>
          </Section>

          {item.previousOwners && item.previousOwners.length > 0 && (
            <Section>
              <SectionTitle>Previous Owners</SectionTitle>
              <OwnershipHistory>
                {item.previousOwners.map((owner, index) => (
                  <OwnershipEntry key={index}>
                    <OwnerName>{owner}</OwnerName>
                  </OwnershipEntry>
                ))}
              </OwnershipHistory>
            </Section>
          )}

          <Section>
            <SectionTitle>First Appearance</SectionTitle>
            <MetaItem>Episode {item.firstAppearance}</MetaItem>
          </Section>
        </InfoSection>
      </Content>
    </Container>
  );
};

export default ItemDetail; 