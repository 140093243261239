import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, Typography, Box, Paper } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Theme } from '@mui/material/styles';
import { Faction } from '../../../types/lore';
import { factions, filterFactions } from '../../../services/factions';

const Title = styled(Typography)<{ theme?: Theme }>`
  font-family: ${({ theme }) => theme.typography.fontFamily};
  font-size: ${({ theme }) => theme.typography.h2.fontSize}px;
  color: ${({ theme }) => theme.palette.text.primary};
  margin-bottom: ${({ theme }) => theme.spacing(4)};
`;

const FilterBar = styled(Box)<{ theme?: Theme }>`
  display: flex;
  gap: ${({ theme }) => theme.spacing(3)};
  margin-bottom: ${({ theme }) => theme.spacing(4)};
  flex-wrap: wrap;
`;

const Select = styled('select')<{ theme?: Theme }>`
  background: ${({ theme }) => theme.palette.background.default};
  border: 1px solid ${({ theme }) => theme.palette.primary.main}33;
  border-radius: ${({ theme }) => theme.shape.borderRadius}px;
  padding: ${({ theme }) => theme.spacing(2)};
  color: ${({ theme }) => theme.palette.text.primary};
  font-family: ${({ theme }) => theme.typography.fontFamily};
  cursor: pointer;

  &:focus {
    outline: none;
    border-color: ${({ theme }) => theme.palette.primary.main};
  }

  option {
    background: ${({ theme }) => theme.palette.background.default};
    color: ${({ theme }) => theme.palette.text.primary};
  }
`;

const SearchInput = styled('input')<{ theme?: Theme }>`
  flex: 1;
  min-width: 200px;
  background: ${({ theme }) => theme.palette.background.default};
  border: 1px solid ${({ theme }) => theme.palette.primary.main}33;
  border-radius: ${({ theme }) => theme.shape.borderRadius}px;
  padding: ${({ theme }) => theme.spacing(2)};
  color: ${({ theme }) => theme.palette.text.primary};
  font-family: ${({ theme }) => theme.typography.fontFamily};

  &:focus {
    outline: none;
    border-color: ${({ theme }) => theme.palette.primary.main};
  }
`;

const FactionsGrid = styled(Box)<{ theme?: Theme }>`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
  gap: ${({ theme }) => theme.spacing(4)};
`;

const FactionCard = styled(Paper)<{ theme?: Theme }>`
  background: ${({ theme }) => theme.palette.background.default}CC;
  border-radius: ${({ theme }) => theme.shape.borderRadius}px;
  border: 1px solid ${({ theme }) => theme.palette.primary.main}33;
  overflow: hidden;
  cursor: pointer;
  transition: all 0.2s ease-in-out;

  &:hover {
    border-color: ${({ theme }) => theme.palette.primary.main};
    transform: translateY(-4px);
  }

  &:active {
    transform: scale(0.98);
  }
`;

const FactionImage = styled(Box)<{ theme?: Theme; imageUrl?: string }>`
  height: 200px;
  background-image: ${({ imageUrl }) => imageUrl ? `url(${imageUrl})` : 'none'};
  background-color: ${({ theme }) => theme.palette.background.default};
  background-size: cover;
  background-position: center;
  border-bottom: 1px solid ${({ theme }) => theme.palette.primary.main}33;
  position: relative;
`;

const TypeBadge = styled(Box)<{ theme?: Theme; type: Faction['type'] }>`
  position: absolute;
  top: ${({ theme }) => theme.spacing(1)};
  right: ${({ theme }) => theme.spacing(1)};
  padding: ${({ theme }) => theme.spacing(0.5)} ${({ theme }) => theme.spacing(1)};
  border-radius: ${({ theme }) => theme.shape.borderRadius}px;
  font-size: ${({ theme }) => theme.typography.body2.fontSize}px;
  font-family: ${({ theme }) => theme.typography.fontFamily};
  background: ${({ type }) => {
    switch (type) {
      case 'political': return '#2196f399';
      case 'religious': return '#9c27b099';
      case 'criminal': return '#f4433699';
      case 'military': return '#78909c99';
      default: return '#9e9e9e99';
    }
  }};
  color: ${({ theme }) => theme.palette.text.primary};
  backdrop-filter: blur(4px);
`;

const FactionInfo = styled(Box)<{ theme?: Theme }>`
  padding: ${({ theme }) => theme.spacing(3)};
`;

const FactionName = styled(Typography)<{ theme?: Theme }>`
  font-family: ${({ theme }) => theme.typography.fontFamily};
  font-size: ${({ theme }) => theme.typography.h5.fontSize}px;
  color: ${({ theme }) => theme.palette.text.primary};
  margin-bottom: ${({ theme }) => theme.spacing(1)};
`;

const FactionMeta = styled(Box)<{ theme?: Theme }>`
  display: flex;
  gap: ${({ theme }) => theme.spacing(2)};
  margin-bottom: ${({ theme }) => theme.spacing(2)};
  flex-wrap: wrap;
`;

const MetaItem = styled(Box)<{ theme?: Theme }>`
  font-size: ${({ theme }) => theme.typography.body2.fontSize}px;
  color: ${({ theme }) => theme.palette.text.secondary};
  background: ${({ theme }) => theme.palette.background.default}CC;
  padding: ${({ theme }) => theme.spacing(0.5)} ${({ theme }) => theme.spacing(1)};
  border-radius: ${({ theme }) => theme.shape.borderRadius}px;
  border: 1px solid ${({ theme }) => theme.palette.primary.main}33;
`;

const FactionDescription = styled(Typography)<{ theme?: Theme }>`
  font-size: ${({ theme }) => theme.typography.body2.fontSize}px;
  color: ${({ theme }) => theme.palette.text.primary};
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
`;

const NoResults = styled(Typography)<{ theme?: Theme }>`
  text-align: center;
  color: ${({ theme }) => theme.palette.text.secondary};
  font-family: ${({ theme }) => theme.typography.fontFamily};
  font-size: ${({ theme }) => theme.typography.body1.fontSize}px;
  padding: ${({ theme }) => theme.spacing(4)};
`;

const Factions: React.FC = () => {
  const navigate = useNavigate();
  const [searchQuery, setSearchQuery] = useState('');
  const [campaign, setCampaign] = useState('all');
  const [factionType, setFactionType] = useState('all');
  const [status, setStatus] = useState('all');
  const [filteredFactions, setFilteredFactions] = useState<Faction[]>(factions);

  useEffect(() => {
    const filtered = filterFactions(
      factions,
      campaign === 'all' ? undefined : parseInt(campaign),
      factionType === 'all' ? undefined : factionType,
      status === 'all' ? undefined : status,
      searchQuery
    );
    setFilteredFactions(filtered);
  }, [campaign, factionType, status, searchQuery]);

  return (
    <Container maxWidth="lg" sx={{ py: 4 }}>
      <Title variant="h1">Factions & Organizations</Title>
      <FilterBar>
        <SearchInput
          type="text"
          placeholder="Search factions..."
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
        <Select
          value={campaign}
          onChange={(e) => setCampaign(e.target.value)}
        >
          <option value="all">All Campaigns</option>
          <option value="1">Campaign 1</option>
          <option value="2">Campaign 2</option>
          <option value="3">Campaign 3</option>
        </Select>
        <Select
          value={factionType}
          onChange={(e) => setFactionType(e.target.value)}
        >
          <option value="all">All Types</option>
          <option value="political">Political</option>
          <option value="religious">Religious</option>
          <option value="criminal">Criminal</option>
          <option value="military">Military</option>
          <option value="other">Other</option>
        </Select>
        <Select
          value={status}
          onChange={(e) => setStatus(e.target.value)}
        >
          <option value="all">All Statuses</option>
          <option value="active">Active</option>
          <option value="disbanded">Disbanded</option>
          <option value="destroyed">Destroyed</option>
          <option value="unknown">Unknown</option>
        </Select>
      </FilterBar>

      {filteredFactions.length > 0 ? (
        <FactionsGrid>
          {filteredFactions.map((faction) => (
            <FactionCard
              key={faction.id}
              onClick={() => navigate(`/lore/factions/${faction.id}`)}
              elevation={1}
            >
              <FactionImage imageUrl={faction.imageUrl}>
                <TypeBadge type={faction.type}>
                  {faction.type.charAt(0).toUpperCase() + faction.type.slice(1)}
                </TypeBadge>
              </FactionImage>
              <FactionInfo>
                <FactionName variant="h5">{faction.name}</FactionName>
                <FactionMeta>
                  <MetaItem>Campaign {faction.campaign}</MetaItem>
                  <MetaItem>{faction.status}</MetaItem>
                  {faction.headquarters && (
                    <MetaItem>HQ: {faction.headquarters}</MetaItem>
                  )}
                </FactionMeta>
                <FactionDescription variant="body2">{faction.description}</FactionDescription>
              </FactionInfo>
            </FactionCard>
          ))}
        </FactionsGrid>
      ) : (
        <NoResults variant="body1">
          No factions found matching your search criteria
        </NoResults>
      )}
    </Container>
  );
};

export default Factions; 