import { Character, Location, Item, Faction, Event, Deity, LoreFilter } from '../types/lore';

const mockCharacters: Character[] = [
  {
    id: 'vex',
    name: 'Vex\'ahlia',
    race: 'Half-Elf',
    class: ['Ranger', 'Rogue'],
    level: [15, 5],
    campaign: 1,
    imageUrl: '/images/characters/vex.jpg',
    ddbUrl: 'https://www.dndbeyond.com/characters/vex',
    description: 'Twin sister of Vax\'ildan, skilled archer and ranger.',
    status: 'alive',
    affiliations: ['Vox Machina', 'Whitestone Council'],
    firstAppearance: 'C1E1',
    background: 'Noble',
    alignment: 'Chaotic Good',
    stats: {
      strength: 14,
      dexterity: 20,
      constitution: 15,
      intelligence: 14,
      wisdom: 16,
      charisma: 17,
      str: 14,
      dex: 20,
      con: 15,
      int: 14,
      wis: 16,
      cha: 17
    },
    savingThrows: ['Dexterity', 'Intelligence'],
    skills: ['Acrobatics', 'Animal Handling', 'Investigation', 'Perception', 'Stealth', 'Survival'],
    equipment: ['Fenthras', 'Blazing Bowstring', 'Death Walker\'s Ward'],
    features: ['Favored Enemy', 'Natural Explorer', 'Archery Fighting Style', 'Colossus Slayer']
  }
];

const mockLocations: Location[] = [
  {
    id: 'whitestone',
    name: 'Whitestone',
    type: 'city',
    description: 'A city known for its Sun Tree and resilience against the Briarwoods.',
    campaign: 1,
    imageUrl: '/images/locations/whitestone.jpg',
    coordinates: [45.5, -122.6],
    region: 'Tal\'Dorei',
    continent: 'Tal\'Dorei',
    population: 4200,
    government: 'Council',
    notableResidents: ['Percy de Rolo', 'Cassandra de Rolo'],
    notableNPCs: ['Keeper Yennen', 'Archibald Desnay'],
    pointsOfInterest: ['Sun Tree', 'Castle Whitestone', 'Whitestone Ziggurat'],
    history: 'Once ruled by the de Rolo family, then taken over by the Briarwoods, now restored.',
    spoilerLevel: 2,
    status: 'inhabited',
    firstAppearance: 'C1E24',
    relatedLocations: ['greyskull-keep', 'emon']
  }
];

const mockItems: Item[] = [
  {
    id: 'fenthras',
    name: 'Fenthras, Wrath of the Fey Warden',
    type: 'weapon',
    rarity: 'legendary',
    description: 'A powerful bow that once belonged to a corrupted archfey.',
    campaign: 1,
    imageUrl: '/images/items/fenthras.jpg',
    attunement: true,
    properties: ['Vestige of Divergence', 'Magical', 'Ranged'],
    history: 'Retrieved from the Feywild after defeating Saundor.',
    currentOwner: 'Vex\'ahlia',
    previousOwners: ['Saundor'],
    spoilerLevel: 3,
    firstAppearance: 'C1E59'
  }
];

const mockFactions: Faction[] = [
  {
    id: 'vox-machina',
    name: 'Vox Machina',
    type: 'adventuring',
    description: 'A legendary group of adventurers who saved Tal\'Dorei multiple times.',
    campaign: 1,
    imageUrl: '/images/factions/vox-machina.jpg',
    leader: 'None (Group leadership)',
    members: ['Vex\'ahlia', 'Vax\'ildan', 'Percy', 'Keyleth', 'Grog', 'Pike', 'Scanlan'],
    headquarters: 'Greyskull Keep',
    goals: ['Protect Tal\'Dorei', 'Help those in need', 'Seek adventure'],
    allies: ['Tal\'Dorei Council', 'Whitestone'],
    enemies: ['Briarwoods', 'Chroma Conclave'],
    history: 'Originally known as the S.H.I.T.s, they grew from a small mercenary group into legendary heroes.',
    spoilerLevel: 1,
    territory: ['Tal\'Dorei', 'Whitestone'],
    status: 'retired',
    firstAppearance: 'C1E1'
  }
];

const mockEvents: Event[] = [
  {
    id: 'chroma-conclave-attack',
    name: 'Attack of the Chroma Conclave',
    type: 'catastrophe',
    description: 'Four ancient chromatic dragons attacked Emon and other major cities.',
    campaign: 1,
    date: '810 PD',
    location: 'Emon',
    participants: [
      'Thordak',
      'Raishan',
      'Umbrasyl',
      'Vorugal',
      'Vox Machina'
    ],
    consequences: [
      'Fall of Emon',
      'Devastation of Tal\'Dorei',
      'Formation of the resistance'
    ],
    relatedEvents: ['death-of-sovereign-uriel', 'siege-of-emon'],
    spoilerLevel: 2,
    relatedLocations: ['emon', 'whitestone', 'vassalheim'],
    relatedFactions: ['chroma-conclave', 'vox-machina', 'council-of-taldorei'],
    outcome: 'Destruction of major cities and shift in power across Tal\'Dorei',
    firstAppearance: 'C1E39'
  }
];

const mockDeities: Deity[] = [
  {
    id: 'sarenrae',
    name: 'Sarenrae',
    title: 'The Everlight',
    description: 'Goddess of redemption, healing, and the sun.',
    campaign: 1,
    imageUrl: '/images/deities/sarenrae.jpg',
    alignment: 'Neutral Good',
    domains: ['Life', 'Light', 'Healing'],
    symbol: 'Angel\'s Wings',
    worshipers: ['Pike Trickfoot', 'Keeper Yennen'],
    holyDays: ['Summer Solstice', 'Dawn'],
    enemies: ['Vecna', 'Tiamat'],
    history: 'One of the prime deities who fought against the Betrayer Gods.',
    spoilerLevel: 1
  }
];

export const getCharacters = async (): Promise<Character[]> => {
  await new Promise(resolve => setTimeout(resolve, 1000));
  return mockCharacters;
};

export const getLocations = async (): Promise<Location[]> => {
  await new Promise(resolve => setTimeout(resolve, 1000));
  return mockLocations;
};

export const getItems = async (): Promise<Item[]> => {
  await new Promise(resolve => setTimeout(resolve, 1000));
  return mockItems;
};

export const getFactions = async (): Promise<Faction[]> => {
  await new Promise(resolve => setTimeout(resolve, 1000));
  return mockFactions;
};

export const getEvents = async (): Promise<Event[]> => {
  await new Promise(resolve => setTimeout(resolve, 1000));
  return mockEvents;
};

export const getDeities = async (): Promise<Deity[]> => {
  await new Promise(resolve => setTimeout(resolve, 1000));
  return mockDeities;
};

export const getLoreById = async (type: string, id: string): Promise<Character | Location | Item | Faction | Event | Deity | undefined> => {
  await new Promise(resolve => setTimeout(resolve, 1000));
  switch (type) {
    case 'character':
      return mockCharacters.find(char => char.id === id);
    case 'location':
      return mockLocations.find(loc => loc.id === id);
    case 'item':
      return mockItems.find(item => item.id === id);
    case 'faction':
      return mockFactions.find(faction => faction.id === id);
    case 'event':
      return mockEvents.find(event => event.id === id);
    case 'deity':
      return mockDeities.find(deity => deity.id === id);
    default:
      return undefined;
  }
};

export const getCharacterById = async (id: string): Promise<Character | undefined> => {
  const characters = await getCharacters();
  return characters.find(character => character.id === id);
};

export const filterCharacters = (
  characters: Character[],
  filter: LoreFilter
): Character[] => {
  return characters.filter(character => {
    if (filter.campaign && character.campaign !== filter.campaign) {
      return false;
    }

    if (filter.type && character.class.every(c => c !== filter.type)) {
      return false;
    }

    if (filter.status && character.status !== filter.status) {
      return false;
    }

    if (filter.searchQuery) {
      const query = filter.searchQuery.toLowerCase();
      const matchName = character.name.toLowerCase().includes(query);
      const matchRace = character.race.toLowerCase().includes(query);
      const matchClass = character.class.some(c => c.toLowerCase().includes(query));
      const matchDescription = character.description.toLowerCase().includes(query);
      if (!matchName && !matchRace && !matchClass && !matchDescription) {
        return false;
      }
    }

    return true;
  });
}; 