import { Deity } from '../types/lore';

export const deities: Deity[] = [
  {
    id: 'raven-queen',
    name: 'The Raven Queen',
    title: 'Matron of Death',
    description: 'The goddess of death, fate, and winter. She guides souls to their final rest and maintains the balance between life and death.',
    campaign: 1,
    imageUrl: '/images/deities/raven-queen.jpg',
    alignment: 'Lawful Neutral',
    domains: ['Death', 'Fate', 'Winter'],
    symbol: 'Raven\'s head in profile',
    worshipers: ['Vax\'ildan', 'Lieve\'tel Toluse', 'Champions of the Raven Queen'],
    holyDays: ['Night of Ascension'],
    enemies: ['Vecna', 'Those who seek immortality'],
    history: 'Once a mortal queen who ascended to godhood by overthrowing the previous god of death.',
    spoilerLevel: 2,
    firstAppearance: 'C1E57'
  },
  {
    id: 'melora',
    name: 'Melora',
    title: 'The Wild Mother',
    description: 'The goddess of wilderness, nature, and the sea. She represents the untamed beauty and power of the natural world.',
    campaign: 1,
    imageUrl: '/images/deities/melora.jpg',
    alignment: 'Neutral Good',
    domains: ['Nature', 'Tempest', 'Life'],
    symbol: 'Wreath of intertwined vines and waves',
    worshipers: ['Keyleth', 'Fjord', 'Druids', 'Sailors'],
    holyDays: ['First Bloom', 'Harvest Close'],
    enemies: ['Tharizdun', 'Those who destroy nature'],
    history: 'One of the prime deities who fought against the Betrayer Gods during the Calamity.',
    spoilerLevel: 1,
    firstAppearance: 'C1E1'
  },
  {
    id: 'ioun',
    name: 'Ioun',
    title: 'The Knowing Mistress',
    description: 'The goddess of knowledge, study, and prophecy. She encourages the pursuit of learning and the preservation of history.',
    campaign: 1,
    imageUrl: '/images/deities/ioun.jpg',
    alignment: 'Neutral',
    domains: ['Knowledge', 'Arcana'],
    symbol: 'Open eye with an infinity symbol',
    worshipers: ['Scholars', 'Wizards', 'Librarians', 'The Cobalt Soul'],
    holyDays: ['Day of Knowing'],
    enemies: ['Tharizdun', 'Those who destroy knowledge'],
    history: 'Grievously wounded during the Calamity but survived through the help of her followers.',
    spoilerLevel: 1,
    firstAppearance: 'C1E1'
  }
];

export const getDeities = async (): Promise<Deity[]> => {
  await new Promise(resolve => setTimeout(resolve, 1000));
  return deities;
};

export const getDeityById = async (id: string): Promise<Deity | undefined> => {
  await new Promise(resolve => setTimeout(resolve, 1000));
  return deities.find(deity => deity.id === id);
};

export const getDeitiesByDomain = async (domain: string): Promise<Deity[]> => {
  await new Promise(resolve => setTimeout(resolve, 1000));
  return deities.filter(deity => deity.domains.includes(domain));
};

export const getDeitiesByAlignment = async (alignment: string): Promise<Deity[]> => {
  await new Promise(resolve => setTimeout(resolve, 1000));
  return deities.filter(deity => deity.alignment === alignment);
}; 