import React, { useEffect, useState } from 'react';
import { MapContainer, TileLayer, Marker, Popup, useMap } from 'react-leaflet';
import { LatLngBounds, LatLng, CRS } from 'leaflet';
import 'leaflet/dist/leaflet.css';
import { Box, Paper, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

interface MapRegion {
  name: string;
  bounds: [number, number, number, number];
  description: string;
}

interface JourneyPoint {
  name: string;
  position: [number, number];
  description: string;
}

interface Props {
  regions: MapRegion[];
  journeyPoints: JourneyPoint[];
}

const MapWrapper = styled(Box)(({ theme }) => ({
  height: 'calc(100vh - 64px)',
  width: '100%',
  position: 'relative',
  '& .leaflet-container': {
    height: '100%',
    width: '100%',
    background: theme.palette.background.paper,
  },
}));

const CustomTileLayer: React.FC = () => {
  const map = useMap();
  
  useEffect(() => {
    // Set the maximum bounds of the map
    const bounds = new LatLngBounds(
      new LatLng(-85, -180),
      new LatLng(85, 180)
    );
    map.setMaxBounds(bounds);
    map.setMinZoom(2);
    map.setMaxZoom(6);
  }, [map]);

  return (
    <TileLayer
      url="/map-tiles/{z}/{x}/{y}.webp"
      bounds={new LatLngBounds([-85, -180], [85, 180])}
      noWrap={true}
      attribution="Map data &copy; Critical Role"
    />
  );
};

const ExandrianMap: React.FC<Props> = ({ regions, journeyPoints }) => {
  const [center] = useState<[number, number]>([0, 0]);
  const [zoom] = useState(3);

  return (
    <MapWrapper>
      <MapContainer
        center={center}
        zoom={zoom}
        crs={CRS.Simple}
        scrollWheelZoom={true}
        zoomControl={true}
        attributionControl={true}
      >
        <CustomTileLayer />
        
        {regions.map((region, index) => (
          <Marker
            key={`region-${index}`}
            position={[region.bounds[0], region.bounds[1]]}
          >
            <Popup>
              <Typography variant="h6">{region.name}</Typography>
              <Typography variant="body2">{region.description}</Typography>
            </Popup>
          </Marker>
        ))}

        {journeyPoints.map((point, index) => (
          <Marker
            key={`journey-${index}`}
            position={point.position}
          >
            <Popup>
              <Typography variant="h6">{point.name}</Typography>
              <Typography variant="body2">{point.description}</Typography>
            </Popup>
          </Marker>
        ))}
      </MapContainer>
    </MapWrapper>
  );
};

export default ExandrianMap; 