import React from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import styled from '@emotion/styled';
import { motion } from 'framer-motion';
import { customTheme } from '../../../theme/theme';
import { getEventById, getRelatedEvents } from '../../../services/events';

const Container = styled.div`
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: ${props => props.theme.spacing(4)};
`;

const BackButton = styled.button`
  background: none;
  border: none;
  color: ${props => props.theme.palette.text.secondary};
  font-family: ${props => props.theme.typography.fontFamily.primary};
  font-size: ${props => props.theme.fontSizes.lg}px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: ${props => props.theme.spacing(1)};
  margin-bottom: ${props => props.theme.spacing(4)};
  padding: 0;

  &:hover {
    color: ${props => props.theme.palette.text.primary};
  }
`;

const Content = styled(motion.div)`
  display: grid;
  grid-template-columns: 1fr 2fr;
  gap: ${props => props.theme.spacing(4)};

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

const ImageSection = styled.div`
  position: sticky;
  top: ${props => props.theme.spacing(4)};
`;

const EventImage = styled.div<{ imageUrl?: string }>`
  width: 100%;
  height: 400px;
  background-image: ${props => props.imageUrl ? `url(${props.imageUrl})` : 'none'};
  background-color: ${props => props.theme.palette.background.default};
  background-size: cover;
  background-position: center;
  border-radius: ${props => props.theme.borderRadius.md};
  border: 1px solid ${props => props.theme.colors.accent.gold}33;
  margin-bottom: ${props => props.theme.spacing(3)};
`;

const InfoSection = styled.div``;

const Header = styled.div`
  margin-bottom: ${props => props.theme.spacing(4)};
`;

const Title = styled.h1`
  font-family: ${props => props.theme.typography.fontFamily.display};
  font-size: ${props => props.theme.fontSizes['4xl']}px;
  color: ${props => props.theme.palette.text.primary};
  margin-bottom: ${props => props.theme.spacing(2)};
`;

const MetaInfo = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: ${props => props.theme.spacing(2)};
  margin-bottom: ${props => props.theme.spacing(3)};
`;

const MetaItem = styled.span<{ variant?: 'type' | 'default' }>`
  font-size: ${props => props.theme.fontSizes.md}px;
  color: ${props => props.theme.palette.text.primary};
  background: ${props => props.variant === 'type' ? 
    `${props.theme.colors.accent.gold}33` : 
    `${props.theme.palette.background.default}CC`};
  padding: ${props => props.theme.spacing(1)} ${props => props.theme.spacing(2)};
  border-radius: ${props => props.theme.borderRadius.sm};
  border: 1px solid ${props => props.theme.colors.accent.gold}33;
`;

const Description = styled.p`
  font-size: ${props => props.theme.fontSizes.lg}px;
  color: ${props => props.theme.palette.text.primary};
  line-height: 1.6;
  margin-bottom: ${props => props.theme.spacing(4)};
`;

const Section = styled.div`
  margin-bottom: ${props => props.theme.spacing(4)};
`;

const SectionTitle = styled.h2`
  font-family: ${props => props.theme.typography.fontFamily.display};
  font-size: ${props => props.theme.fontSizes['2xl']}px;
  color: ${props => props.theme.palette.text.primary};
  margin-bottom: ${props => props.theme.spacing(2)};
`;

const ParticipantList = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: ${props => props.theme.spacing(2)};
`;

const ParticipantItem = styled.div`
  padding: ${props => props.theme.spacing(1)} ${props => props.theme.spacing(2)};
  background: ${props => props.theme.palette.background.default}CC;
  border-radius: ${props => props.theme.borderRadius.sm};
  border: 1px solid ${props => props.theme.colors.accent.gold}33;
  font-size: ${props => props.theme.fontSizes.md}px;
  color: ${props => props.theme.palette.text.primary};
`;

const RelatedGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: ${props => props.theme.spacing(2)};
`;

const RelatedCard = styled.div`
  padding: ${props => props.theme.spacing(2)};
  background: ${props => props.theme.palette.background.default}CC;
  border-radius: ${props => props.theme.borderRadius.sm};
  border: 1px solid ${props => props.theme.colors.accent.gold}33;
  cursor: pointer;

  &:hover {
    border-color: ${props => props.theme.colors.accent.gold};
  }
`;

const RelatedName = styled.h3`
  font-family: ${props => props.theme.typography.fontFamily.display};
  font-size: ${props => props.theme.fontSizes.lg}px;
  color: ${props => props.theme.palette.text.primary};
  margin-bottom: ${props => props.theme.spacing(1)};
`;

const RelatedDate = styled.span`
  font-size: ${props => props.theme.fontSizes.sm}px;
  color: ${props => props.theme.palette.text.secondary};
`;

const Outcome = styled.div`
  padding: ${props => props.theme.spacing(3)};
  background: ${props => props.theme.palette.background.default}CC;
  border-radius: ${props => props.theme.borderRadius.md};
  border: 1px solid ${props => props.theme.colors.accent.gold}33;
  margin-bottom: ${props => props.theme.spacing(4)};
`;

const OutcomeText = styled.p`
  font-size: ${props => props.theme.fontSizes.lg}px;
  color: ${props => props.theme.palette.text.primary};
  line-height: 1.6;
`;

const NotFound = styled.div`
  text-align: center;
  padding: ${props => props.theme.spacing(4)};
  color: ${props => props.theme.palette.text.secondary};
  font-family: ${props => props.theme.typography.fontFamily.primary};
  font-size: ${props => props.theme.fontSizes.xl}px;
`;

const EventDetail: React.FC = () => {
  const { eventId } = useParams<{ eventId: string }>();
  const navigate = useNavigate();
  const event = eventId ? getEventById(eventId) : undefined;
  const relatedEvents = eventId ? getRelatedEvents(eventId) : [];

  if (!event) {
    return (
      <Container>
        <BackButton onClick={() => navigate('/lore/events')}>
          ← Back to Events
        </BackButton>
        <NotFound>Event not found</NotFound>
      </Container>
    );
  }

  return (
    <Container>
      <BackButton onClick={() => navigate('/lore/events')}>
        ← Back to Events
      </BackButton>
      
      <Content
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: -20 }}
        transition={{ duration: 0.3 }}
      >
        <ImageSection>
          <EventImage imageUrl={event.imageUrl} />
          <MetaInfo>
            <MetaItem variant="type">
              {event.type.charAt(0).toUpperCase() + event.type.slice(1)}
            </MetaItem>
            <MetaItem>Campaign {event.campaign || 'Pre-Stream'}</MetaItem>
            <MetaItem>{event.date}</MetaItem>
          </MetaInfo>
        </ImageSection>

        <InfoSection>
          <Header>
            <Title>{event.name}</Title>
            <Description>{event.description}</Description>
          </Header>

          {event.location && (
            <Section>
              <SectionTitle>Location</SectionTitle>
              <ParticipantItem>{event.location}</ParticipantItem>
            </Section>
          )}

          {event.participants && event.participants.length > 0 && (
            <Section>
              <SectionTitle>Participants</SectionTitle>
              <ParticipantList>
                {event.participants.map((participant, index) => (
                  <ParticipantItem key={index}>
                    {participant}
                  </ParticipantItem>
                ))}
              </ParticipantList>
            </Section>
          )}

          {event.outcome && (
            <Section>
              <SectionTitle>Outcome</SectionTitle>
              <Outcome>
                <OutcomeText>{event.outcome}</OutcomeText>
              </Outcome>
            </Section>
          )}

          {relatedEvents.length > 0 && (
            <Section>
              <SectionTitle>Related Events</SectionTitle>
              <RelatedGrid>
                {relatedEvents.map((relatedEvent) => (
                  <RelatedCard
                    key={relatedEvent.id}
                    onClick={() => navigate(`/lore/events/${relatedEvent.id}`)}
                  >
                    <RelatedName>{relatedEvent.name}</RelatedName>
                    <RelatedDate>{relatedEvent.date}</RelatedDate>
                  </RelatedCard>
                ))}
              </RelatedGrid>
            </Section>
          )}

          <Section>
            <SectionTitle>First Appearance</SectionTitle>
            <MetaItem>Episode {event.firstAppearance}</MetaItem>
          </Section>
        </InfoSection>
      </Content>
    </Container>
  );
};

export default EventDetail; 