import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Container,
  Grid,
  Paper,
  Typography,
  TextField,
  Button,
  IconButton,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Divider,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Alert,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Tooltip,
} from '@mui/material';
import {
  Add as AddIcon,
  Remove as RemoveIcon,
  Delete as DeleteIcon,
  Search as SearchIcon,
  Save as SaveIcon,
  Visibility as ViewIcon,
} from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import { MonsterListItem, Encounter } from '../../types/monster';
import { dndApi } from '../../services/dndApi';

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  height: '100%',
}));

const EncounterBuilder: React.FC = () => {
  const navigate = useNavigate();
  const [encounterName, setEncounterName] = useState('');
  const [partySize, setPartySize] = useState(4);
  const [partyLevel, setPartyLevel] = useState(1);
  const [selectedMonsters, setSelectedMonsters] = useState<{
    monster: MonsterListItem;
    count: number;
  }[]>([]);
  const [monsterSearchOpen, setMonsterSearchOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState<MonsterListItem[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  // Load any pending monsters from the Monster Database
  useEffect(() => {
    const pendingMonsters = sessionStorage.getItem('pendingEncounterMonsters');
    if (pendingMonsters) {
      const monsters = JSON.parse(pendingMonsters);
      setSelectedMonsters(prev => {
        const newMonsters = [...prev];
        monsters.forEach((pendingMonster: { monster: MonsterListItem; count: number }) => {
          const existingIndex = newMonsters.findIndex(m => m.monster.id === pendingMonster.monster.id);
          if (existingIndex >= 0) {
            newMonsters[existingIndex].count += pendingMonster.count;
          } else {
            newMonsters.push(pendingMonster);
          }
        });
        return newMonsters;
      });
      sessionStorage.removeItem('pendingEncounterMonsters');
    }
  }, []);

  // XP Thresholds per Character Level
  const xpThresholds = {
    1: { easy: 25, medium: 50, hard: 75, deadly: 100 },
    2: { easy: 50, medium: 100, hard: 150, deadly: 200 },
    3: { easy: 75, medium: 150, hard: 225, deadly: 400 },
    4: { easy: 125, medium: 250, hard: 375, deadly: 500 },
    5: { easy: 250, medium: 500, hard: 750, deadly: 1100 },
    6: { easy: 300, medium: 600, hard: 900, deadly: 1400 },
    7: { easy: 350, medium: 750, hard: 1100, deadly: 1700 },
    8: { easy: 450, medium: 900, hard: 1400, deadly: 2100 },
    9: { easy: 550, medium: 1100, hard: 1600, deadly: 2400 },
    10: { easy: 600, medium: 1200, hard: 1900, deadly: 2800 },
    11: { easy: 800, medium: 1600, hard: 2400, deadly: 3600 },
    12: { easy: 1000, medium: 2000, hard: 3000, deadly: 4500 },
    13: { easy: 1100, medium: 2200, hard: 3400, deadly: 5100 },
    14: { easy: 1250, medium: 2500, hard: 3800, deadly: 5700 },
    15: { easy: 1400, medium: 2800, hard: 4300, deadly: 6400 },
    16: { easy: 1600, medium: 3200, hard: 4800, deadly: 7200 },
    17: { easy: 2000, medium: 3900, hard: 5900, deadly: 8800 },
    18: { easy: 2100, medium: 4200, hard: 6300, deadly: 9500 },
    19: { easy: 2400, medium: 4900, hard: 7300, deadly: 10900 },
    20: { easy: 2800, medium: 5700, hard: 8500, deadly: 12700 },
  };

  // XP Multipliers based on number of monsters
  const getXPMultiplier = (monsterCount: number) => {
    if (monsterCount === 0) return 0;
    if (monsterCount === 1) return 1;
    if (monsterCount === 2) return 1.5;
    if (monsterCount <= 6) return 2;
    if (monsterCount <= 10) return 2.5;
    if (monsterCount <= 14) return 3;
    return 4;
  };

  const handleSearch = async () => {
    if (!searchTerm.trim()) return;

    try {
      setLoading(true);
      const results = await dndApi.searchMonsters(searchTerm);
      setSearchResults(results);
      setError(null);
    } catch (err) {
      setError('Failed to search monsters. Please try again later.');
      console.error('Error searching monsters:', err);
    } finally {
      setLoading(false);
    }
  };

  const handleAddMonster = (monster: MonsterListItem) => {
    const existingMonster = selectedMonsters.find(m => m.monster.id === monster.id);
    if (existingMonster) {
      setSelectedMonsters(selectedMonsters.map(m =>
        m.monster.id === monster.id ? { ...m, count: m.count + 1 } : m
      ));
    } else {
      setSelectedMonsters([...selectedMonsters, { monster, count: 1 }]);
    }
    setMonsterSearchOpen(false);
    setSearchTerm('');
    setSearchResults([]);
  };

  const handleViewMonsterDetails = (monsterId: string) => {
    navigate(`/tools/monsters?highlight=${monsterId}`);
  };

  const handleRemoveMonster = (monsterId: string) => {
    setSelectedMonsters(selectedMonsters.filter(m => m.monster.id !== monsterId));
  };

  const handleUpdateCount = (monsterId: string, delta: number) => {
    setSelectedMonsters(selectedMonsters.map(m => {
      if (m.monster.id === monsterId) {
        const newCount = Math.max(1, m.count + delta);
        return { ...m, count: newCount };
      }
      return m;
    }));
  };

  const calculateEncounterDifficulty = () => {
    const totalMonsters = selectedMonsters.reduce((sum, { count }) => sum + count, 0);
    const baseXP = selectedMonsters.reduce(
      (sum, { monster, count }) => sum + monster.challengeRating * count * 100,
      0
    );
    const adjustedXP = baseXP * getXPMultiplier(totalMonsters);
    const partyThresholds = xpThresholds[partyLevel as keyof typeof xpThresholds];
    const totalPartyThresholds = {
      easy: partyThresholds.easy * partySize,
      medium: partyThresholds.medium * partySize,
      hard: partyThresholds.hard * partySize,
      deadly: partyThresholds.deadly * partySize,
    };

    if (adjustedXP < totalPartyThresholds.easy) return 'Trivial';
    if (adjustedXP < totalPartyThresholds.medium) return 'Easy';
    if (adjustedXP < totalPartyThresholds.hard) return 'Medium';
    if (adjustedXP < totalPartyThresholds.deadly) return 'Hard';
    return 'Deadly';
  };

  const handleSaveEncounter = () => {
    const encounter: Encounter = {
      id: Date.now().toString(),
      name: encounterName,
      monsters: selectedMonsters.map(({ monster, count }) => ({
        monsterId: monster.id,
        count,
        monster,
      })),
      difficulty: calculateEncounterDifficulty() as Encounter['difficulty'],
      totalXP: selectedMonsters.reduce(
        (sum, { monster, count }) => sum + monster.challengeRating * count * 100,
        0
      ),
      adjustedXP: selectedMonsters.reduce(
        (sum, { monster, count }) => sum + monster.challengeRating * count * 100,
        0
      ) * getXPMultiplier(selectedMonsters.reduce((sum, { count }) => sum + count, 0)),
      partyLevel,
      partySize,
    };

    // Save encounter to local storage for now
    const savedEncounters = JSON.parse(localStorage.getItem('savedEncounters') || '[]');
    localStorage.setItem('savedEncounters', JSON.stringify([...savedEncounters, encounter]));

    // Show success message
    setError('Encounter saved successfully!');
    setTimeout(() => setError(null), 3000);
  };

  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      <Typography variant="h2" component="h1" gutterBottom>
        Encounter Builder
      </Typography>

      {error && (
        <Alert severity={error.includes('success') ? 'success' : 'error'} sx={{ mb: 2 }}>
          {error}
        </Alert>
      )}

      <Grid container spacing={3}>
        <Grid item xs={12} md={4}>
          <StyledPaper>
            <Typography variant="h5" gutterBottom>
              Party Setup
            </Typography>
            <TextField
              fullWidth
              label="Encounter Name"
              value={encounterName}
              onChange={(e) => setEncounterName(e.target.value)}
              sx={{ mb: 2 }}
            />
            <FormControl fullWidth sx={{ mb: 2 }}>
              <InputLabel>Party Size</InputLabel>
              <Select
                value={partySize}
                label="Party Size"
                onChange={(e) => setPartySize(Number(e.target.value))}
              >
                {[1, 2, 3, 4, 5, 6, 7, 8].map((size) => (
                  <MenuItem key={size} value={size}>{size} Players</MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl fullWidth sx={{ mb: 2 }}>
              <InputLabel>Party Level</InputLabel>
              <Select
                value={partyLevel}
                label="Party Level"
                onChange={(e) => setPartyLevel(Number(e.target.value))}
              >
                {Array.from({ length: 20 }, (_, i) => i + 1).map((level) => (
                  <MenuItem key={level} value={level}>Level {level}</MenuItem>
                ))}
              </Select>
            </FormControl>

            <Box sx={{ display: 'flex', gap: 1, mb: 4 }}>
              <Button
                variant="contained"
                fullWidth
                startIcon={<AddIcon />}
                onClick={() => setMonsterSearchOpen(true)}
              >
                Add Monster
              </Button>
              <Button
                variant="outlined"
                onClick={() => navigate('/tools/monsters')}
              >
                Browse All
              </Button>
            </Box>

            <Typography variant="h6" gutterBottom>
              Selected Monsters
            </Typography>
            <List>
              {selectedMonsters.map(({ monster, count }) => (
                <React.Fragment key={monster.id}>
                  <ListItem>
                    <ListItemText
                      primary={monster.name}
                      secondary={`CR ${monster.challengeRating} | Count: ${count}`}
                    />
                    <ListItemSecondaryAction>
                      <Tooltip title="View Details">
                        <IconButton
                          edge="end"
                          onClick={() => handleViewMonsterDetails(monster.id)}
                          size="small"
                        >
                          <ViewIcon />
                        </IconButton>
                      </Tooltip>
                      <IconButton
                        edge="end"
                        onClick={() => handleUpdateCount(monster.id, -1)}
                        size="small"
                      >
                        <RemoveIcon />
                      </IconButton>
                      <Typography
                        component="span"
                        sx={{ mx: 1 }}
                      >
                        {count}
                      </Typography>
                      <IconButton
                        edge="end"
                        onClick={() => handleUpdateCount(monster.id, 1)}
                        size="small"
                      >
                        <AddIcon />
                      </IconButton>
                      <IconButton
                        edge="end"
                        onClick={() => handleRemoveMonster(monster.id)}
                        size="small"
                        sx={{ ml: 1 }}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </ListItemSecondaryAction>
                  </ListItem>
                  <Divider />
                </React.Fragment>
              ))}
            </List>

            {selectedMonsters.length > 0 && (
              <Box sx={{ mt: 2 }}>
                <Alert severity={
                  calculateEncounterDifficulty() === 'Deadly' ? 'error' :
                  calculateEncounterDifficulty() === 'Hard' ? 'warning' :
                  'info'
                }>
                  Difficulty: {calculateEncounterDifficulty()}
                </Alert>
                <Button
                  variant="contained"
                  fullWidth
                  onClick={handleSaveEncounter}
                  sx={{ mt: 2 }}
                  disabled={!encounterName}
                  startIcon={<SaveIcon />}
                >
                  Save Encounter
                </Button>
              </Box>
            )}
          </StyledPaper>
        </Grid>

        <Grid item xs={12} md={8}>
          <StyledPaper>
            <Typography variant="h5" gutterBottom>
              Encounter Details
            </Typography>
            {selectedMonsters.length > 0 ? (
              <>
                <Typography variant="h6" gutterBottom>
                  Total XP: {selectedMonsters.reduce(
                    (sum, { monster, count }) => sum + monster.challengeRating * count * 100,
                    0
                  )}
                </Typography>
                <Typography variant="h6" gutterBottom>
                  Adjusted XP: {selectedMonsters.reduce(
                    (sum, { monster, count }) => sum + monster.challengeRating * count * 100,
                    0
                  ) * getXPMultiplier(selectedMonsters.reduce((sum, { count }) => sum + count, 0))}
                </Typography>
                <Typography variant="body1" paragraph>
                  XP Thresholds for {partySize} level {partyLevel} characters:
                </Typography>
                <Grid container spacing={2}>
                  <Grid item xs={3}>
                    <Typography color="success.main">
                      Easy: {xpThresholds[partyLevel as keyof typeof xpThresholds].easy * partySize}
                    </Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography color="info.main">
                      Medium: {xpThresholds[partyLevel as keyof typeof xpThresholds].medium * partySize}
                    </Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography color="warning.main">
                      Hard: {xpThresholds[partyLevel as keyof typeof xpThresholds].hard * partySize}
                    </Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography color="error.main">
                      Deadly: {xpThresholds[partyLevel as keyof typeof xpThresholds].deadly * partySize}
                    </Typography>
                  </Grid>
                </Grid>
              </>
            ) : (
              <Typography variant="body1" color="text.secondary">
                Add monsters to see encounter details
              </Typography>
            )}
          </StyledPaper>
        </Grid>
      </Grid>

      <Dialog
        open={monsterSearchOpen}
        onClose={() => setMonsterSearchOpen(false)}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>Add Monster</DialogTitle>
        <DialogContent>
          <Box sx={{ display: 'flex', gap: 1, mb: 2 }}>
            <TextField
              fullWidth
              label="Search Monsters"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              onKeyPress={(e) => e.key === 'Enter' && handleSearch()}
            />
            <Button
              variant="contained"
              onClick={handleSearch}
              startIcon={<SearchIcon />}
            >
              Search
            </Button>
          </Box>

          {loading ? (
            <Box display="flex" justifyContent="center" my={4}>
              <CircularProgress />
            </Box>
          ) : (
            <List>
              {searchResults.map((monster) => (
                <React.Fragment key={monster.id}>
                  <ListItem button onClick={() => handleAddMonster(monster)}>
                    <ListItemText
                      primary={monster.name}
                      secondary={`${monster.size} ${monster.type} | CR ${monster.challengeRating}`}
                    />
                    <IconButton edge="end" size="small">
                      <AddIcon />
                    </IconButton>
                  </ListItem>
                  <Divider />
                </React.Fragment>
              ))}
            </List>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setMonsterSearchOpen(false)}>Cancel</Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default EncounterBuilder; 