import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import styled from '@emotion/styled';
import { motion } from 'framer-motion';
import { useTheme } from '@mui/material/styles';
import { Episode } from '../../types';
import { getEpisodeDetails, getEmbedUrl, getCampaignFromEpisodeId } from '../../services/beacon';

const EpisodeDetail: React.FC = () => {
  const { episodeId } = useParams<{ episodeId: string }>();
  const [episode, setEpisode] = useState<Episode | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const theme = useTheme();

  const Container = styled.div`
    max-width: 1200px;
    margin: 0 auto;
    padding: ${theme.spacing(4)};
  `;

  const Header = styled.div`
    margin-bottom: ${theme.spacing(4)};
  `;

  const Title = styled.h1`
    font-family: ${theme.typography.fontFamily.display};
    font-size: ${theme.typography.h1.fontSize};
    color: ${theme.palette.text.primary};
    margin-bottom: ${theme.spacing(2)};
  `;

  const Metadata = styled.div`
    display: flex;
    gap: ${theme.spacing(4)};
    color: ${theme.palette.text.secondary};
    font-family: ${theme.typography.fontFamily.primary};
    margin-bottom: ${theme.spacing(4)};
  `;

  const VideoContainer = styled.div`
    position: relative;
    width: 100%;
    padding-top: 56.25%; // 16:9 aspect ratio
    background: ${theme.palette.background.dark};
    border-radius: ${theme.shape.borderRadius}px;
    overflow: hidden;
    margin-bottom: ${theme.spacing(4)};
  `;

  const VideoIframe = styled.iframe`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: none;
  `;

  const Description = styled.div`
    font-family: ${theme.typography.fontFamily.primary};
    color: ${theme.palette.text.primary};
    line-height: 1.8;
    font-size: ${theme.typography.body1.fontSize};
    margin-bottom: ${theme.spacing(4)};
    padding: ${theme.spacing(4)};
    background: ${theme.palette.background.paper}CC;
    border-radius: ${theme.shape.borderRadius}px;
    border: 1px solid ${theme.palette.primary.main}33;
  `;

  const RelatedContent = styled.div`
    background: ${theme.palette.background.paper}CC;
    border-radius: ${theme.shape.borderRadius}px;
    padding: ${theme.spacing(4)};
    border: 1px solid ${theme.palette.primary.main}33;
  `;

  const RelatedTitle = styled.h2`
    font-family: ${theme.typography.fontFamily.display};
    font-size: ${theme.typography.h3.fontSize};
    color: ${theme.palette.text.primary};
    margin-bottom: ${theme.spacing(4)};
  `;

  const LoadingContainer = styled(motion.div)`
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 400px;
    color: ${theme.palette.text.primary};
  `;

  const ErrorMessage = styled.div`
    color: ${theme.palette.error.main};
    text-align: center;
    font-family: ${theme.typography.fontFamily.primary};
    font-size: ${theme.typography.body1.fontSize};
    padding: ${theme.spacing(4)};
  `;

  useEffect(() => {
    const fetchEpisode = async () => {
      if (episodeId) {
        setLoading(true);
        setError(null);
        try {
          const data = await getEpisodeDetails(episodeId);
          if (data) {
            setEpisode(data);
          } else {
            setError('Episode not found');
          }
        } catch (err) {
          setError('Failed to load episode');
        } finally {
          setLoading(false);
        }
      }
    };

    fetchEpisode();
  }, [episodeId]);

  if (loading) {
    return (
      <LoadingContainer
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
      >
        Loading episode...
      </LoadingContainer>
    );
  }

  if (error || !episode) {
    return (
      <Container>
        <ErrorMessage>
          {error || 'Episode not found'}
        </ErrorMessage>
      </Container>
    );
  }

  return (
    <Container>
      <Header>
        <Title>{episode.title}</Title>
        <Metadata>
          <span>Air Date: {new Date(episode.airDate).toLocaleDateString()}</span>
          <span>Campaign {getCampaignFromEpisodeId(episode.id)}</span>
        </Metadata>
      </Header>

      <VideoContainer>
        <VideoIframe
          src={getEmbedUrl(episode.id)}
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        />
      </VideoContainer>

      <Description>{episode.summary}</Description>

      <RelatedContent>
        <RelatedTitle>Related Content</RelatedTitle>
        {/* Add related content here (e.g., fan art, discussions, etc.) */}
      </RelatedContent>
    </Container>
  );
};

export default EpisodeDetail; 