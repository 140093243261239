import { Episode } from '../types';

const BEACON_BASE_URL = 'https://beacon.tv';

interface BeaconEpisode {
  id: string;
  title: string;
  description: string;
  publishedAt: string;
  thumbnailUrl: string;
  videoUrl: string;
  campaign: {
    number: number;
    title: string;
  };
  episodeNumber: number;
}

// Utility function to format episode number with leading zero if needed
const formatEpisodeNumber = (num: number): string => {
  return num < 10 ? `0${num}` : `${num}`;
};

// Convert our internal ID format to Beacon URL format
export const getBeaconUrl = (campaignNumber: number, episodeNumber: number): string => {
  return `${BEACON_BASE_URL}/content/c${campaignNumber}-e${formatEpisodeNumber(episodeNumber)}`;
};

// Convert Beacon URL format to our internal ID format
export const parseBeaconUrl = (url: string): { campaignNumber: number; episodeNumber: number } | null => {
  const match = url.match(/c(\d+)-e(\d+)/);
  if (!match) return null;
  
  return {
    campaignNumber: parseInt(match[1]),
    episodeNumber: parseInt(match[2])
  };
};

// Mock data for development since we can't fetch from Beacon API directly
const mockEpisodes: Episode[] = [
  {
    id: 'ep1',
    title: 'The Dawn of Adventure',
    summary: 'The party meets for the first time in a tavern...',
    airDate: '2024-01-01',
    spoilerLevel: 0,
    videoUrl: 'https://youtube.com/watch?v=episode1',
    transcriptUrl: undefined,
    campaign: 3,
    episodeNumber: 1,
    duration: 240,
    cast: ['Matt Mercer', 'Laura Bailey', 'Travis Willingham'],
    highlights: [
      {
        timestamp: 3600,
        description: 'Epic battle begins'
      }
    ]
  },
  {
    id: 'ep2',
    title: 'Mysteries Unfold',
    summary: 'Our heroes discover an ancient artifact...',
    airDate: '2024-01-08',
    spoilerLevel: 0,
    videoUrl: 'https://youtube.com/watch?v=episode2',
    transcriptUrl: undefined,
    campaign: 3,
    episodeNumber: 2,
    duration: 245,
    cast: ['Matt Mercer', 'Laura Bailey', 'Travis Willingham'],
    highlights: [
      {
        timestamp: 4200,
        description: 'Major plot revelation'
      }
    ]
  }
];

export const fetchEpisodes = async (campaignNumber?: number): Promise<Episode[]> => {
  // In a real implementation, we would fetch from Beacon's API
  // For now, return mock data filtered by campaign if specified
  return mockEpisodes.filter(episode => {
    if (!campaignNumber) return true;
    return parseInt(episode.id.charAt(1)) === campaignNumber;
  });
};

export const getEpisodeDetails = async (episodeId: string): Promise<Episode | null> => {
  // In a real implementation, we would fetch from Beacon's API
  // For now, return mock data
  const episode = mockEpisodes.find(ep => ep.id === episodeId);
  return episode || null;
};

export const getEmbedUrl = (episodeId: string): string => {
  const campaignNumber = parseInt(episodeId.charAt(1));
  const episodeNumber = parseInt(episodeId.slice(3));
  return `${BEACON_BASE_URL}/embed/c${campaignNumber}-e${formatEpisodeNumber(episodeNumber)}`;
};

// Utility function to get campaign number from episode ID
export const getCampaignFromEpisodeId = (episodeId: string): number => {
  return parseInt(episodeId.charAt(1));
};

// Utility function to get episode number from episode ID
export const getEpisodeNumberFromId = (episodeId: string): number => {
  return parseInt(episodeId.slice(3));
}; 