import React from 'react';
import { useNavigate } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { Container, Typography, Button, Box, Grid, useTheme } from '@mui/material';

const Home: React.FC = () => {
  const navigate = useNavigate();
  const theme = useTheme();

  const HeroSection = styled('div')`
    background: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)),
      ${({ theme }) => theme.palette.background.default};
    min-height: 600px;
    display: flex;
    align-items: center;
    color: ${({ theme }) => theme.palette.text.primary};
    padding: ${({ theme }) => theme.spacing(4)} 0;
  `;

  const Title = styled(Typography)`
    font-family: ${theme.typography.fontFamily.display};
    font-size: ${theme.typography.h1.fontSize};
    margin-bottom: ${theme.spacing(4)};
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  `;

  const Subtitle = styled(Typography)`
    font-size: ${theme.typography.h4.fontSize};
    margin-bottom: ${theme.spacing(6)};
    max-width: 800px;
  `;

  const FeatureCard = styled(Box)`
    background: ${theme.palette.background.paper}CC;
    border: 1px solid ${theme.palette.primary.main}33;
    border-radius: ${theme.shape.borderRadius}px;
    padding: ${theme.spacing(4)};
    text-align: center;
    transition: transform 0.2s ease-in-out;

    &:hover {
      transform: translateY(-4px);
      border-color: ${theme.palette.primary.main};
    }
  `;

  const FeatureTitle = styled(Typography)`
    font-family: ${theme.typography.fontFamily.display};
    font-size: ${theme.typography.h5.fontSize};
    color: ${theme.palette.primary.main};
    margin-bottom: ${theme.spacing(2)};
  `;

  const FeatureDescription = styled(Typography)`
    color: ${theme.palette.text.secondary};
    margin-bottom: ${theme.spacing(4)};
  `;

  const StyledButton = styled(Button)`
    background: ${theme.palette.primary.main}22;
    border: 1px solid ${theme.palette.primary.main};
    color: ${theme.palette.primary.main};
    padding: ${theme.spacing(1)} ${theme.spacing(4)};
    font-family: ${theme.typography.fontFamily.primary};
    
    &:hover {
      background: ${theme.palette.primary.main}33;
    }
  `;

  const features = [
    {
      title: 'Episodes',
      description: 'Watch and track your progress through every episode of Critical Role.',
      path: '/episodes'
    },
    {
      title: 'Interactive Map',
      description: 'Explore the world of Exandria with our detailed interactive map.',
      path: '/map'
    },
    {
      title: 'Lore Database',
      description: 'Discover characters, locations, items, and more from the series.',
      path: '/lore'
    }
  ];

  return (
    <>
      <HeroSection>
        <Container>
          <Title variant="h1">
            Welcome to Critical Role Companion
          </Title>
          <Subtitle variant="h2">
            Your ultimate guide to the world of Exandria and the adventures of Vox Machina,
            the Mighty Nein, and Bells Hells.
          </Subtitle>
          <StyledButton
            variant="outlined"
            size="large"
            onClick={() => navigate('/episodes')}
          >
            Start Watching
          </StyledButton>
        </Container>
      </HeroSection>

      <Container sx={{ py: 8 }}>
        <Grid container spacing={4}>
          {features.map((feature) => (
            <Grid item xs={12} md={4} key={feature.title}>
              <FeatureCard>
                <FeatureTitle variant="h3">
                  {feature.title}
                </FeatureTitle>
                <FeatureDescription>
                  {feature.description}
                </FeatureDescription>
                <StyledButton
                  variant="outlined"
                  onClick={() => navigate(feature.path)}
                >
                  Explore
                </StyledButton>
              </FeatureCard>
            </Grid>
          ))}
        </Grid>
      </Container>
    </>
  );
};

export default Home; 