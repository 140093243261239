import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Container, Grid, Typography, Box, Chip, Paper } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Theme } from '@mui/material/styles';
import { Deity } from '../../../types/lore';
import { getDeities } from '../../../services/deities';

const Title = styled(Typography)<{ theme?: Theme }>`
  font-family: ${({ theme }) => theme.typography.fontFamily};
  font-size: ${({ theme }) => theme.typography.h2.fontSize}px;
  margin-bottom: ${({ theme }) => theme.spacing(4)};
  text-align: center;
`;

const DeityCard = styled(Paper)<{ theme?: Theme }>`
  background: ${({ theme }) => theme.palette.background.paper};
  border-radius: ${({ theme }) => theme.shape.borderRadius}px;
  padding: ${({ theme }) => theme.spacing(3)};
  transition: transform 0.2s ease-in-out;
  cursor: pointer;
  height: 100%;

  &:hover {
    transform: scale(1.02);
  }
`;

const DeityImage = styled('img')<{ theme?: Theme }>`
  width: 100%;
  height: 200px;
  object-fit: cover;
  border-radius: ${({ theme }) => theme.shape.borderRadius}px;
  margin-bottom: ${({ theme }) => theme.spacing(2)};
`;

const DeityName = styled(Typography)<{ theme?: Theme }>`
  font-family: ${({ theme }) => theme.typography.fontFamily};
  font-size: ${({ theme }) => theme.typography.h5.fontSize}px;
  margin-bottom: ${({ theme }) => theme.spacing(1)};
`;

const DeityTitle = styled(Typography)<{ theme?: Theme }>`
  font-size: ${({ theme }) => theme.typography.body1.fontSize}px;
  color: ${({ theme }) => theme.palette.text.secondary};
  margin-bottom: ${({ theme }) => theme.spacing(2)};
`;

const DomainChip = styled(Chip)<{ theme?: Theme }>`
  margin: ${({ theme }) => theme.spacing(0.5)};
  font-size: ${({ theme }) => theme.typography.body2.fontSize}px;
`;

const AlignmentChip = styled(Chip)<{ theme?: Theme }>`
  margin: ${({ theme }) => theme.spacing(0.5)};
  font-size: ${({ theme }) => theme.typography.body2.fontSize}px;
`;

const FilterSection = styled(Box)<{ theme?: Theme }>`
  margin-bottom: ${({ theme }) => theme.spacing(4)};
  padding: ${({ theme }) => theme.spacing(2)};
  background: ${({ theme }) => theme.palette.background.paper};
  border-radius: ${({ theme }) => theme.shape.borderRadius}px;
`;

const FilterTitle = styled(Typography)<{ theme?: Theme }>`
  font-size: ${({ theme }) => theme.typography.body2.fontSize}px;
  color: ${({ theme }) => theme.palette.text.secondary};
  margin-bottom: ${({ theme }) => theme.spacing(1)};
`;

const FilterChip = styled(Chip)<{ theme?: Theme }>`
  margin: ${({ theme }) => theme.spacing(0.5)};
  font-size: ${({ theme }) => theme.typography.body2.fontSize}px;
`;

const Description = styled(Typography)<{ theme?: Theme }>`
  font-size: ${({ theme }) => theme.typography.h6.fontSize}px;
  margin-bottom: ${({ theme }) => theme.spacing(4)};
  text-align: center;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
`;

const Deities: React.FC = () => {
  const [deities, setDeities] = useState<Deity[]>([]);
  const [selectedDomains, setSelectedDomains] = useState<string[]>([]);
  const [selectedAlignments, setSelectedAlignments] = useState<string[]>([]);
  const [domains, setDomains] = useState<string[]>([]);
  const [alignments, setAlignments] = useState<string[]>([]);

  useEffect(() => {
    const fetchDeities = async () => {
      const fetchedDeities = await getDeities();
      setDeities(fetchedDeities);

      // Extract unique domains and alignments
      const allDomains = new Set<string>();
      const allAlignments = new Set<string>();

      fetchedDeities.forEach(deity => {
        deity.domains.forEach(domain => allDomains.add(domain));
        allAlignments.add(deity.alignment);
      });

      setDomains(Array.from(allDomains).sort());
      setAlignments(Array.from(allAlignments).sort());
    };

    fetchDeities();
  }, []);

  const filteredDeities = deities.filter(deity => {
    const matchDomains = selectedDomains.length === 0 ||
      selectedDomains.some(domain => deity.domains.includes(domain));
    const matchAlignment = selectedAlignments.length === 0 ||
      selectedAlignments.includes(deity.alignment);
    return matchDomains && matchAlignment;
  });

  const handleDomainToggle = (domain: string) => {
    setSelectedDomains(prev =>
      prev.includes(domain)
        ? prev.filter(d => d !== domain)
        : [...prev, domain]
    );
  };

  const handleAlignmentToggle = (alignment: string) => {
    setSelectedAlignments(prev =>
      prev.includes(alignment)
        ? prev.filter(a => a !== alignment)
        : [...prev, alignment]
    );
  };

  return (
    <Container maxWidth="lg" sx={{ py: 4 }}>
      <Title variant="h1">
        Deities of Exandria
      </Title>
      <Description variant="body1" color="text.secondary">
        Explore the pantheon of gods and goddesses that shape the world of Critical Role.
      </Description>

      <FilterSection>
        <FilterTitle variant="body2">Filter by Domain</FilterTitle>
        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
          {domains.map(domain => (
            <FilterChip
              key={domain}
              label={domain}
              onClick={() => handleDomainToggle(domain)}
              color={selectedDomains.includes(domain) ? 'primary' : 'default'}
              variant={selectedDomains.includes(domain) ? 'filled' : 'outlined'}
            />
          ))}
        </Box>
      </FilterSection>

      <FilterSection>
        <FilterTitle variant="body2">Filter by Alignment</FilterTitle>
        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
          {alignments.map(alignment => (
            <FilterChip
              key={alignment}
              label={alignment}
              onClick={() => handleAlignmentToggle(alignment)}
              color={selectedAlignments.includes(alignment) ? 'primary' : 'default'}
              variant={selectedAlignments.includes(alignment) ? 'filled' : 'outlined'}
            />
          ))}
        </Box>
      </FilterSection>

      <Grid container spacing={3}>
        {filteredDeities.map(deity => (
          <Grid item xs={12} sm={6} md={4} key={deity.id}>
            <Link to={`/lore/deities/${deity.id}`} style={{ textDecoration: 'none' }}>
              <DeityCard elevation={1}>
                <DeityImage src={deity.imageUrl} alt={deity.name} />
                <DeityName variant="h5">{deity.name}</DeityName>
                <DeityTitle variant="subtitle1">{deity.title}</DeityTitle>
                <Box sx={{ mb: 2 }}>
                  {deity.domains.map((domain, index) => (
                    <DomainChip
                      key={index}
                      label={domain}
                      color="primary"
                      variant="outlined"
                      size="small"
                    />
                  ))}
                </Box>
                <AlignmentChip
                  label={deity.alignment}
                  color="secondary"
                  variant="outlined"
                  size="small"
                />
              </DeityCard>
            </Link>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default Deities; 