import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Container, Typography, Paper } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Theme } from '@mui/material/styles';
import mapboxgl from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import { Location } from '../../../types/lore';
import { locations, filterLocations } from '../../../services/locations';

// Replace with your Mapbox access token
mapboxgl.accessToken = 'YOUR_MAPBOX_ACCESS_TOKEN';

const Sidebar = styled(Box)<{ theme?: Theme }>`
  width: 300px;
  flex-shrink: 0;
  overflow-y: auto;
  background: ${({ theme }) => theme.palette.background.paper}CC;
  border-radius: ${({ theme }) => theme.shape.borderRadius}px;
  border: 1px solid ${({ theme }) => theme.palette.primary.main}33;

  @media (max-width: 768px) {
    width: 100%;
    max-height: 300px;
  }
`;

const MapWrapper = styled(Box)<{ theme?: Theme }>`
  flex: 1;
  position: relative;
  border-radius: ${({ theme }) => theme.shape.borderRadius}px;
  overflow: hidden;
  border: 1px solid ${({ theme }) => theme.palette.primary.main}33;
  min-height: 500px;
`;

const FilterBar = styled(Box)<{ theme?: Theme }>`
  padding: ${({ theme }) => theme.spacing(4)};
  border-bottom: 1px solid ${({ theme }) => theme.palette.primary.main}33;
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(2)};
`;

const Select = styled('select')<{ theme?: Theme }>`
  background: ${({ theme }) => theme.palette.background.default};
  border: 1px solid ${({ theme }) => theme.palette.primary.main}33;
  border-radius: ${({ theme }) => theme.shape.borderRadius}px;
  padding: ${({ theme }) => theme.spacing(2)};
  color: ${({ theme }) => theme.palette.text.primary};
  font-family: ${({ theme }) => theme.typography.fontFamily};
  width: 100%;

  &:focus {
    outline: none;
    border-color: ${({ theme }) => theme.palette.primary.main};
  }

  option {
    background: ${({ theme }) => theme.palette.background.default};
    color: ${({ theme }) => theme.palette.text.primary};
  }
`;

const SearchInput = styled('input')<{ theme?: Theme }>`
  width: 100%;
  background: ${({ theme }) => theme.palette.background.default};
  border: 1px solid ${({ theme }) => theme.palette.primary.main}33;
  border-radius: ${({ theme }) => theme.shape.borderRadius}px;
  padding: ${({ theme }) => theme.spacing(2)};
  color: ${({ theme }) => theme.palette.text.primary};
  font-family: ${({ theme }) => theme.typography.fontFamily};

  &:focus {
    outline: none;
    border-color: ${({ theme }) => theme.palette.primary.main};
  }
`;

const LocationList = styled(Box)<{ theme?: Theme }>`
  padding: ${({ theme }) => theme.spacing(2)};
`;

const LocationCard = styled(Paper)<{ theme?: Theme; active?: boolean }>`
  padding: ${({ theme }) => theme.spacing(2)};
  background: ${({ theme, active }) => active ? theme.palette.primary.main + '22' : theme.palette.background.paper}CC;
  border: 1px solid ${({ theme, active }) => active ? theme.palette.primary.main : 'transparent'};
  cursor: pointer;
  margin-bottom: ${({ theme }) => theme.spacing(2)};
  transition: all 0.2s ease-in-out;

  &:hover {
    background: ${({ theme }) => theme.palette.primary.main}22;
    transform: translateX(4px);
  }

  &:active {
    transform: scale(0.98);
  }
`;

const LocationName = styled(Typography)<{ theme?: Theme }>`
  font-family: ${({ theme }) => theme.typography.fontFamily};
  font-size: ${({ theme }) => theme.typography.h6.fontSize}px;
  color: ${({ theme }) => theme.palette.text.primary};
  margin-bottom: ${({ theme }) => theme.spacing(1)};
`;

const LocationType = styled(Typography)<{ theme?: Theme }>`
  font-size: ${({ theme }) => theme.typography.body2.fontSize}px;
  color: ${({ theme }) => theme.palette.text.secondary};
`;

const Locations: React.FC = () => {
  const navigate = useNavigate();
  const mapContainer = useRef<HTMLDivElement>(null);
  const map = useRef<mapboxgl.Map | null>(null);
  const [selectedLocation, setSelectedLocation] = useState<Location | null>(null);
  const [filteredLocations, setFilteredLocations] = useState<Location[]>(locations);
  const [filter, setFilter] = useState({
    type: 'all',
    continent: 'all',
    search: '',
  });

  useEffect(() => {
    if (mapContainer.current && !map.current) {
      map.current = new mapboxgl.Map({
        container: mapContainer.current,
        style: 'mapbox://styles/mapbox/dark-v10',
        center: [0, 0], // Set your initial coordinates
        zoom: 2,
      });

      map.current.on('load', () => {
        // Add your map layers and markers here
      });
    }
  }, []);

  useEffect(() => {
    const type = filter.type === 'all' ? undefined : filter.type;
    const searchQuery = filter.search || undefined;
    
    const filtered = filterLocations(locations, undefined, type, undefined, searchQuery);
    setFilteredLocations(filtered);
  }, [filter]);

  const handleFilterChange = (key: keyof typeof filter, value: string) => {
    setFilter(prev => ({ ...prev, [key]: value }));
  };

  return (
    <Container maxWidth="xl" sx={{ py: 4 }}>
      <Box sx={{ 
        display: 'flex', 
        gap: 4,
        height: 'calc(100vh - 128px)',
        '@media (max-width: 768px)': {
          flexDirection: 'column',
          height: 'auto'
        }
      }}>
        <Sidebar>
          <FilterBar>
            <Select
              value={filter.type}
              onChange={(e) => handleFilterChange('type', e.target.value)}
            >
              <option value="all">All Types</option>
              <option value="city">Cities</option>
              <option value="landmark">Landmarks</option>
              <option value="dungeon">Dungeons</option>
              <option value="wilderness">Wilderness</option>
            </Select>
            <Select
              value={filter.continent}
              onChange={(e) => handleFilterChange('continent', e.target.value)}
            >
              <option value="all">All Continents</option>
              <option value="taldorei">Tal'Dorei</option>
              <option value="wildemount">Wildemount</option>
              <option value="marquet">Marquet</option>
              <option value="issylra">Issylra</option>
            </Select>
            <SearchInput
              type="text"
              placeholder="Search locations..."
              value={filter.search}
              onChange={(e) => handleFilterChange('search', e.target.value)}
            />
          </FilterBar>
          <LocationList>
            {filteredLocations.map((location) => (
              <LocationCard
                key={location.id}
                active={selectedLocation?.id === location.id}
                onClick={() => navigate(`/lore/locations/${location.id}`)}
                elevation={selectedLocation?.id === location.id ? 2 : 0}
              >
                <LocationName variant="h6">{location.name}</LocationName>
                <LocationType variant="body2">{location.type}</LocationType>
              </LocationCard>
            ))}
          </LocationList>
        </Sidebar>
        <MapWrapper ref={mapContainer} />
      </Box>
    </Container>
  );
};

export default Locations; 