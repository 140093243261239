import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import { CssBaseline } from '@mui/material';
import { customTheme } from './theme/theme';
import Navigation from './components/Navigation/Navigation';
import Home from './pages/Home/Home';
import Episodes from './pages/Episodes/Episodes';
import EpisodeDetail from './pages/Episodes/EpisodeDetail';
import ExandrianMap from './pages/Map/ExandrianMap';
import LoreLayout from './pages/Lore/LoreLayout';
import Characters from './pages/Lore/Characters/Characters';
import CharacterDetail from './pages/Lore/Characters/CharacterDetail';
import Locations from './pages/Lore/Locations/Locations';
import LocationDetail from './pages/Lore/Locations/LocationDetail';
import Items from './pages/Lore/Items/Items';
import ItemDetail from './pages/Lore/Items/ItemDetail';
import Factions from './pages/Lore/Factions/Factions';
import FactionDetail from './pages/Lore/Factions/FactionDetail';
import Events from './pages/Lore/Events/Events';
import EventDetail from './pages/Lore/Events/EventDetail';
import Deities from './pages/Lore/Deities/Deities';
import DeityDetail from './pages/Lore/Deities/DeityDetail';
import Tools from './pages/Tools/Tools';
import MonsterDatabase from './pages/Tools/MonsterDatabase';
import EncounterBuilder from './pages/Tools/EncounterBuilder';
import Community from './pages/Community/Community';
import Dashboard from './pages/Dashboard/Dashboard';

const App: React.FC = () => {
  return (
    <ThemeProvider theme={customTheme}>
      <CssBaseline />
      <Router>
        <Navigation />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/episodes">
            <Route index element={<Episodes />} />
            <Route path=":id" element={<EpisodeDetail />} />
          </Route>
          <Route path="/map" element={<ExandrianMap regions={[]} journeyPoints={[]} />} />
          <Route path="/tools">
            <Route index element={<Tools />} />
            <Route path="monsters" element={<MonsterDatabase />} />
            <Route path="encounters" element={<EncounterBuilder />} />
          </Route>
          <Route path="/community" element={<Community />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/lore" element={<LoreLayout />}>
            <Route path="characters">
              <Route index element={<Characters />} />
              <Route path=":id" element={<CharacterDetail />} />
            </Route>
            <Route path="locations">
              <Route index element={<Locations />} />
              <Route path=":id" element={<LocationDetail />} />
            </Route>
            <Route path="items">
              <Route index element={<Items />} />
              <Route path=":id" element={<ItemDetail />} />
            </Route>
            <Route path="factions">
              <Route index element={<Factions />} />
              <Route path=":id" element={<FactionDetail />} />
            </Route>
            <Route path="events">
              <Route index element={<Events />} />
              <Route path=":id" element={<EventDetail />} />
            </Route>
            <Route path="deities">
              <Route index element={<Deities />} />
              <Route path=":id" element={<DeityDetail />} />
            </Route>
          </Route>
        </Routes>
      </Router>
    </ThemeProvider>
  );
};

export default App; 